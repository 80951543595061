import { usePackagingSimulatorStatusMutation } from "@/hooks/usePackagingSimulatorStatusMutation";
import { PublishDropdownItemProps } from "./PublishDropdownItem";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { FaUpload } from "react-icons/fa6";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";

export default function ApproveDropdownItem({
  skPackagingSimulator,
}: PublishDropdownItemProps) {
  const { mutateAsync, isPending } = usePackagingSimulatorStatusMutation();

  async function handlePublishItem() {
    if (isPending) return;
    try {
      await mutateAsync({
        SK_simulacao_embalagem: skPackagingSimulator,
        status: "APROVADO",
      });
      toast.success("Simulador de embalagem aprovado com sucesso!");
    } catch (err) {
      validationError(err);
    }
  }

  return (
    <DropdownMenuItem onClick={handlePublishItem}>
      <FaUpload className="mr-2" color={TEXT_COLOR} />
      <Text>Aprovar</Text>
    </DropdownMenuItem>
  );
}
