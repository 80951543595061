import Row from "../Row";
import Text from "../Text/Text";
import MenuSideBar from "../Sidebar/MenuSidebar";
import { getActiveKey } from "@/utils/activeKey";
import { getItem } from "@/utils/storage";
import { getPermissionCookie } from "@/utils/cookies";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { RiLockPasswordFill } from "react-icons/ri";
import { TEXT_COLOR } from "@/constants";
import { TbDoorExit } from "react-icons/tb";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import DeleteLineModal from "../Modal/DeleteLineModal";
import Can from "../Can";

type HeaderProps = {
  title?: string;
};

export default function Header({ title }: HeaderProps) {
  const [visibleModal, setVisibleModal] = useState(false);
  const navigate = useNavigate();
  const permission = getPermissionCookie();
  const user = JSON.parse(getItem(localStorage, "user") || "{}");

  function handleLogout() {
    localStorage.removeItem("user");
    Cookies.remove("permission");
    Cookies.remove("token");
    navigate("/login");
  }

  useEffect(() => {}, [permission]);

  return (
    <Row
      className="p-4 w-full h-[50px] box-border border-solid border border-r-0 border-t-0 border-l-0"
      align="center"
      justify="space-between"
    >
      <Row align="center">
        <MenuSideBar />
        <Text className="mt-1 ml-1 text-sm">{getActiveKey() || title}</Text>
      </Row>
      {/* <div className="h-[50px] overflow-y-hidden absolute -z-10">
        <Lottie
          className="w-full"
          animationData={animationData}
          allowFullScreen
          allowTransparency
        />
      </div> */}
      <Row>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Text>{String(user.name)}</Text>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Can
              condition={permission == "logistics"}
              onTrue={
                <DeleteLineModal
                  handleClose={() => {
                    setVisibleModal((prev) => !prev);
                  }}
                  visible={visibleModal}
                />
              }
            />
            <DropdownMenuItem>
              <Link to="/alterarSenha">
                <Row align="center">
                  <RiLockPasswordFill color={TEXT_COLOR} size={14} />
                  <Text className="mt-1 ml-1">Alterar senha</Text>
                </Row>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleLogout}>
              <Row align="center">
                <TbDoorExit color={TEXT_COLOR} size={14} />
                <Text className="mt-1 ml-1">Sair</Text>
              </Row>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Row>
    </Row>
  );
}
