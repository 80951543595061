import { Dialog, DialogContent } from "@/components/ui/dialog";
import { DialogProps } from "@/types";
import { VersionDropdownProps } from "../Dropdown/VersionDropdown";
import { usePackagingPlanContext } from "../../context";
import { packagingValuesDiffArrays } from "@/utils/diff";
import DiffViewer from "../Viewer/DiffViewer";

type CompareVersionDialogProps = {
  lineValues: VersionDropdownProps["lineValues"];
} & DialogProps;

export default function CompareVersionDialog({
  handleClose,
  lineValues,
  visible,
}: CompareVersionDialogProps) {
  const {
    packagingPlan: { valores_fornecedores },
  } = usePackagingPlanContext();

  const diff = packagingValuesDiffArrays(
    lineValues,
    JSON.parse(valores_fornecedores || "[]")
  );

  return (
    <Dialog onOpenChange={handleClose} open={visible}>
      <DialogContent className="overflow-y-scroll max-h-full">
        <DiffViewer diffs={diff} />
      </DialogContent>
    </Dialog>
  );
}
