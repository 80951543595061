import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import { DecreaseInventoryType } from "../../hooks/useDecreaseInventories";
import { useDecreaseInventoryContext } from "../../context";

export default function ActionDropdown(row: DecreaseInventoryType) {
  const { handleDialogType, handleDecreaseInventory, handleDialog } =
    useDecreaseInventoryContext();
  return (
    <>
      <EditDropdownItem
        onClick={() => {
          handleDecreaseInventory(row);
          handleDialogType("edit");
          handleDialog();
        }}
      />
      <DeleteDropdownItem
        onClick={() => {
          handleDecreaseInventory(row);
          handleDialogType("delete");
          handleDialog();
        }}
      />
    </>
  );
}
