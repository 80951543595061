import { utils, writeFile } from "xlsx";
import { FormatProductionLineType } from "../components/Button/ExportScheduleProductionButton";
import { quantityFormater } from "@/utils/number";
import dayjs from "dayjs";

export function generateScheduleProductionSheet(
  data: FormatProductionLineType[]
) {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([[]]);

  ws["A1"] = { t: "s", v: "Filial" };
  ws["B1"] = { t: "s", v: "Dt_Prev" };
  ws["C1"] = { t: "s", v: "Recurso" };
  ws["D1"] = { t: "s", v: "Sequencia" };
  ws["E1"] = { t: "s", v: "Cod_CCIP" };
  ws["F1"] = { t: "s", v: "Descrição" };
  ws["G1"] = { t: "s", v: "Qtde_Prev" };
  ws["H1"] = { t: "s", v: "Tipo_Plano" };

  const formatedData = data.map((item) => {
    const removeFRTorPTR = item.codigo_linha_de_producao
      .replace("FRT", "")
      .replace("PTR", "");

    return [
      item.filial,
      dayjs(item.dia).format("DD/MM/YYYY"),
      removeFRTorPTR,
      "",
      item.FK_produto,
      item.descricao_produto,
      quantityFormater(Number(item.valor) || 0),
      "",
    ];
  });

  utils.sheet_add_json(ws, formatedData, {
    origin: {
      r: 1,
      c: 0,
    },
    skipHeader: true,
  });
  utils.book_append_sheet(wb, ws, "Programação_Plano_Produção");
  writeFile(wb, "Programação_Plano_Produção.xlsx");
}
