import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { transporterKeys } from "./useTransporters";

type TransporterActiveMutationType = {
  codes: string[];
};

export function useTransporterActiveMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ codes }: TransporterActiveMutationType) => {
      await instance.put("/planningbeer/transporter", { codes });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: transporterKeys.all });
      toast.success("Transportadores ativados com sucesso!");
    },
  });
}
