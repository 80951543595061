import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { SheetDataType } from "../utils/sheet";
import { decreaseInventoryKeys } from "./useDecreaseInventories";

type DecreaseInventoryMutationDataType = {
  fkFactory: string;
  date: string;
  sheet: SheetDataType[];
};

export function useDecreaseInventoryMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: DecreaseInventoryMutationDataType) => {
      return await instance.put("/planningbeer/baixaInventario", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: decreaseInventoryKeys.all });
      toast.success("Carga realizada com sucesso!");
    },
  });
}
