import { cn } from "@/lib/utils";
import { Header, Table, flexRender } from "@tanstack/react-table";
import HeaderActions from "./components/HeaderActions";
import { CSSProperties } from "react";
import { GripVertical } from "lucide-react";
import { getCommonPinningStyles } from "../../utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import VisibleFilter from "../Filter/VisibleFilter";
import Row from "@/components/Row";
import { Filter } from "../Filter";
interface TableColumnHeaderProps<TData> {
  header: Header<TData, any>;
  table: Table<TData>;
}

const TableColumnHeader = <T,>({
  header,
  table,
}: TableColumnHeaderProps<T>) => {
  const enableReorder =
    header.column.columnDef.meta?.enableColumnOrdering === undefined ||
    header.column.columnDef.meta?.enableColumnOrdering;

  const enableMenu =
    header.column.columnDef.meta?.enableMenu === undefined ||
    header.column.columnDef.meta?.enableMenu;

  const { attributes, isDragging, listeners, transform } = useSortable({
    id: header.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <div
      style={{ ...style, ...getCommonPinningStyles(header.column) }}
      className={cn(
        "relative flex group last-of-type:flex-1",
        !table.options.meta?.layout || table.options.meta.layout === "default"
          ? ""
          : "group-last-of-type:flex-1"
      )}
    >
      <div
        key={header.id}
        className={cn(
          `gap-2 p-1 relative border-b space-y-1 group-first-of-type:border-l border-r h-full border-neutral-200 border-t font-normal overflow-hidden text-disabled text-sm text-ellipsis whitespace-nowrap`,
          header.column.getCanSort() ? "cursor-pointer select-none" : "",
          !table.options.meta?.layout || table.options.meta.layout === "default"
            ? ""
            : "group-last-of-type:flex-1",
          header.column.columnDef.meta?.header?.className ||
            table.options.meta?.header?.className
        )}
        style={{
          width: header.getSize(),
        }}
      >
        <div
          className={cn(
            "flex items-center gap-1 overflow-hidden",
            !enableMenu && "h-full px-2  cursor-default"
          )}
        >
          {enableReorder && (
            <button {...attributes} {...listeners}>
              <GripVertical size={14} />
            </button>
          )}
          {enableMenu ? (
            <HeaderActions header={header} />
          ) : header.isPlaceholder ? null : (
            <p className="overflow-hidden text-ellipsis text-neutral-400">
              {flexRender(header.column.columnDef.header, header.getContext())}
            </p>
          )}
        </div>
        {header.column.getCanFilter() ? (
          <Row className="gap-1" align="center" justify="start">
            {/* <VisibleFilter column={header.column} table={table} /> */}
            <Filter column={header.column} table={table} />
          </Row>
        ) : null}
      </div>
      {header.column.getCanResize() && (
        <div
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={`absolute right-0 top-1/2 -translate-y-1/2 h-1/2 rounded-md w-[4px] cursor-col-resize select-none hover:bg-primary-200 touch-none ${
            header.column.getIsResizing()
              ? "bg-primary"
              : "group-hover:bg-neutral-300"
          }`}
        />
      )}
    </div>
  );
};

export default TableColumnHeader;
