import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Checkbox } from "@/components/ui/checkbox";
import { Separator } from "@/components/ui/separator";
import { formatCnpj } from "@/utils/cnpj";
import { memo } from "react";

type TransporterItemProps = {
  nome_fantasia: string;
  razao_social: string;
  cidade: string;
  cnpj: string;
  uf: string;
  codigo: string;
  isSelected: boolean;
  onSelectionChange: (item: { codigo: string }) => void;
};

const TransporterItem = memo(function TransporterItem({
  nome_fantasia,
  razao_social,
  cidade,
  cnpj,
  uf,
  codigo,
  isSelected,
  onSelectionChange,
}: TransporterItemProps) {
  return (
    <Col>
      <Row className="p-3" align="center">
        <Checkbox
          className="mr-4"
          checked={isSelected}
          onClick={() => onSelectionChange({ codigo })}
        />
        <Col className="gap-1">
          <Text className="font-bold">
            {nome_fantasia} - {codigo}
          </Text>
          <Text>
            Razão Social: <strong>{razao_social}</strong>
          </Text>
          <Row className="gap-8">
            <Text>
              {cidade} - {uf}
            </Text>
            <Text>CNPJ: {formatCnpj(cnpj)}</Text>
          </Row>
        </Col>
      </Row>
      <Separator />
    </Col>
  );
});

export default TransporterItem;
