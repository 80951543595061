import { useForm } from "react-hook-form";
import PackagingSimulationForm from "..";
import { PackagingSimulationFormType, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import usePackagingSimulationMutation from "@/hooks/usePackagingSimulationMutation";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import { usePackagingPlanContext } from "@/pages/PlanoEmbalagem/context";
import {
  formatPackagingPlanSupplierValue,
  PackagingPlanSupplierValueResponseType,
} from "@/pages/SimulacoesEmbalagem/utils/packagingSupplier";

export default function CreatePackagingSimulation({
  handleClose,
}: DialogProps) {
  const {
    handlePackagingSimulationData,
    clear,
    packagingSupplier: { handlePackagingSupplierCellValue },
  } = usePackagingSimulationContext();
  const {
    packagingPlan: { SK_plano_embalagem },
  } = usePackagingPlanContext();
  const { mutateAsync, isPending } = usePackagingSimulationMutation();
  const form = useForm<PackagingSimulationFormType>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleSimulation(formData: PackagingSimulationFormType) {
    try {
      const { data } = await mutateAsync({
        SK_simulacao: Number(formData.SK_simulacao),
        observacao: formData.observacao,
        meta: {
          utiliza_valor_fornecedor_plano_embalagem: true,
          FK_plano_embalagem: SK_plano_embalagem,
        },
      });
      clear();
      handlePackagingSimulationData(data);
      const { valores_fornecedores } = data.meta;
      handlePackagingSupplierCellValue(
        formatPackagingPlanSupplierValue(
          valores_fornecedores as unknown as PackagingPlanSupplierValueResponseType[]
        )
      );
      return handleClose();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <PackagingSimulationForm
      form={form}
      onSubmit={handleSimulation}
      button={{ disabled: isPending, title: "Gerar" }}
    />
  );
}
