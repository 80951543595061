import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";

type PackagingPlanVersionMutationType = {
  skPackagingPlan: number;
  revision: string;
  supplierValues: string;
};

export function usePackagingPlanVersionMutation() {
  return useMutation({
    mutationFn: async (data: PackagingPlanVersionMutationType) => {
      return await instance.put("/planningbeer/packagingPlan/version", {
        ...data,
      });
    },
  });
}
