import { useForm } from "react-hook-form";
import { CargoModelForm } from "..";
import { FormValues, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCargoModelContext } from "@/pages/ModeloCarga/context";
import { useEffect } from "react";
import { useCargoModelPatchMutation } from "@/pages/ModeloCarga/hooks/useCargoModelPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

export default function UpdateCargoModelForm() {
  const { mutateAsync, isPending } = useCargoModelPatchMutation();
  const { cargoModel, clear } = useCargoModelContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  async function handleUpdate(formData: FormValues) {
    if (isPending) return;
    try {
      await mutateAsync({
        ...formData,
        SK_modelo_carga: Number(cargoModel.SK_modelo_carga),
      });
      toast.success("Modelo de Carga atualizado com sucesso!");
      clear();
    } catch (err) {
      return validationError(err);
    }
  }

  useEffect(() => {
    form.reset({
      nome: String(cargoModel.nome),
      quantidade_palete: String(cargoModel.quantidade_palete),
    });
  }, [cargoModel]);

  return <CargoModelForm form={form} onSubmit={handleUpdate} />;
}
