import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SheetColumnsType } from "../utils/sheet";
import { supplierStockKey } from "./useSupplierStocks";

type SupplierStockMutationType = {
  sheet: SheetColumnsType[];
  date: string;
};

export function useSupplierStockMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: SupplierStockMutationType) => {
      await instance.put("/planningbeer/supplierstock", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: supplierStockKey.all,
      });
    },
  });
}
