import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { BUILD_LOAD_STATUS, TEXT_COLOR } from "@/constants";
import { SlGraph } from "react-icons/sl";
import { BuildLoadsType, useBuildLoads } from "../../hooks/useBuildLoads";
import { usePackagingPlanContext } from "../../context";
import Table from "@/components/NewTable";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import dayjs from "dayjs";
import ActionCell from "@/components/Cell/ActionCell";
import { useEffect, useMemo } from "react";
import { DropdownMenu, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { FaCheck, FaSync, FaTrash } from "react-icons/fa";
import { useDeleteBuildLoadMutation } from "../../hooks/useDeleteBuildLoadMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { IoMdClose } from "react-icons/io";
import { useUpdateBuildLoadStatusMutation } from "../../hooks/useUpdateBuildLoadStatusMutation";

export type BuildLoadsTable = TableData<BuildLoadsType>;
const columnHelper = createColumnHelper<BuildLoadsTable>();

export default function LoadModal() {
  const { mutateAsync: deleteBuildLoadMutate, isPending } =
    useDeleteBuildLoadMutation();
  const {
    mutateAsync: updateBuildLoadStatusMutate,
    isPending: isPendingUpdateBuildStatus,
  } = useUpdateBuildLoadStatusMutation();
  const {
    packagingPlan: { SK_plano_embalagem },
  } = usePackagingPlanContext();
  const { data, refetch } = useBuildLoads(SK_plano_embalagem);
  const { data: buildLoads = [] } = data || {};

  async function handleUpdateStatus(
    row: BuildLoadsType,
    status: string,
    refusedReason = ""
  ) {
    if (isPendingUpdateBuildStatus) return;
    try {
      await updateBuildLoadStatusMutate({
        skBuildLoad: row.SK_montagem_carga,
        status,
        refusedReason,
      });
      return toast.success("Status atualizado com sucesso!");
    } catch (err) {
      validationError(err);
    }
  }

  async function handleDelete(row: BuildLoadsType) {
    if (isPending) return;
    try {
      await deleteBuildLoadMutate(row.SK_montagem_carga);
      return toast.success("Carga excluída com sucesso!");
    } catch (err) {
      validationError(err);
    }
  }

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "Ações",
        header: "Ações",
        size: 100,
        cell: ({ row }) => {
          return (
            <ActionCell
              items={
                <>
                  <DropdownMenu>
                    <DropdownMenuItem
                      className="flex flex-row gap-2 items-center"
                      onClick={() => {
                        handleUpdateStatus(
                          row.original,
                          BUILD_LOAD_STATUS.CONFIRMADO,
                          ""
                        );
                      }}
                    >
                      <FaCheck color={TEXT_COLOR} />
                      <Text className="mt-1 text-sm">
                        Aceito pela Transportadora{" "}
                      </Text>
                    </DropdownMenuItem>
                  </DropdownMenu>
                  <DropdownMenu>
                    <DropdownMenuItem className="flex flex-row gap-2 items-center">
                      <IoMdClose color={TEXT_COLOR} size={16} />
                      <Text className="mt-1 text-sm">
                        Rejeitado pela Transportadora{" "}
                      </Text>
                    </DropdownMenuItem>
                  </DropdownMenu>
                  <DropdownMenu>
                    <DropdownMenuItem className="flex flex-row gap-2 items-center">
                      <FaSync color={TEXT_COLOR} />
                      <Text className="mt-1 text-sm">Realizar Integração</Text>
                    </DropdownMenuItem>
                  </DropdownMenu>
                  <DropdownMenu>
                    <DropdownMenuItem
                      className="flex flex-row gap-2 items-center"
                      onClick={() => handleDelete(row.original)}
                    >
                      <FaTrash color={TEXT_COLOR} />
                      <Text className="mt-1 text-sm">Excluir</Text>
                    </DropdownMenuItem>
                  </DropdownMenu>
                </>
              }
            />
          );
        },
      }),
      columnHelper.accessor("SK_montagem_carga", {
        header: "ID",
        id: "ID",
        size: 85,
      }),
      columnHelper.accessor("nome_fornecedor", {
        header: "Nome Fornecedor",
        id: "nome_fornecedor",
        size: 250,
      }),
      columnHelper.accessor("nome_transportadora", {
        header: "Nome Transportadora",
        id: "nome_transportadora",
        size: 200,
      }),
      columnHelper.accessor("cidade_transportadora", {
        header: "Cidade Transportadora",
        id: "cidade_transportadora",
        size: 120,
      }),
      columnHelper.accessor("modelo_carga", {
        header: "Modelo de Carga",
        id: "modelo_carga",
        size: 100,
        cell: ({ getValue }) => {
          return <Text color="black">{getValue()?.toUpperCase()}</Text>;
        },
      }),
      columnHelper.accessor("paletes_por_carga", {
        header: "Paletes Por Carga",
        id: "paletes_por_carga",
        size: 100,
      }),
      columnHelper.accessor("dia", {
        header: "Dia",
        id: "dia",
        size: 120,
        cell: ({ getValue }) => {
          return dayjs(getValue()).format("DD/MM/YYYY");
        },
      }),
      columnHelper.accessor("foi_enviado_integracao", {
        header: "Foi realizada Integração",
        id: "foi_enviado_integracao",
        size: 150,
        cell: ({
          row: {
            original: { foi_enviado_integracao },
          },
        }) => {
          return foi_enviado_integracao == 1 ? (
            <Text color="text" className="bg-lime-300 p-2 rounded">
              Integrado
            </Text>
          ) : (
            <Text color="white" className="bg-red-400 p-2 rounded">
              Não Integrado
            </Text>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        id: "status",
        size: 400,
        cell: ({
          row: {
            original: { status },
          },
        }) => {
          return (
            <Text className="p-2 bg-gray-200 text-white rounded" color="black">
              {BUILD_LOAD_STATUS[status] || "Desconhecido"}
            </Text>
          );
        },
      }),
      columnHelper.accessor("motivo_recusa", {
        header: "Motivo da Recusa",
        id: "motivo_recusa",
        size: 200,
      }),
      columnHelper.accessor("id_integracao", {
        header: "ID Integração",
        id: "id_integracao",
        size: 150,
      }),
      columnHelper.accessor("data_envio_integracao", {
        header: "Data Envio da Integração",
        id: "data_envio_integracao",
        size: 150,
        cell: ({
          row: {
            original: { data_envio_integracao },
          },
        }) => {
          if (!data_envio_integracao) return;
          return dayjs(data_envio_integracao).format("DD/MM/YYYY HH:mm:ss");
        },
      }),
      columnHelper.accessor("created_at", {
        header: "Criado em",
        id: "created_at",
        size: 160,
        cell: ({ getValue }) => {
          return dayjs(getValue()).format("DD/MM/YYYY HH:mm:ss");
        },
      }),
      columnHelper.accessor("criado_por", {
        header: "Criado por",
        id: "criado_por",
        size: 200,
      }),
    ];
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="link">
          <Row>
            <SlGraph color={TEXT_COLOR} size={20} />
            <Text className="ml-1 text-sm">Visualizar Cargas</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-11/12 max-w-full h-full">
        <div className="w-full overflow-y-scroll">
          <Text className="text-lg font-bold h-fit">Cargas Geradas</Text>
          <Table<BuildLoadsTable>
            columns={columns}
            data={buildLoads as BuildLoadsTable[]}
            meta={{ layout: "stretch" }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
