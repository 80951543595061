import Col from "@/components/Col";
import Each from "@/components/Each";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";
import { Eye } from "lucide-react";
import React from "react";
import { FaTrash } from "react-icons/fa";
import { CreatedPackagesType } from "../Modal/LoadPlanner";
import Row from "@/components/Row";
import { quantityFormater } from "@/utils/number";

type CreatedPackagesContentProps = {
  packages: Array<CreatedPackagesType>;
  selectedPackage?: CreatedPackagesType;
  onSelectPackage: (id: CreatedPackagesType) => void;
  onDeletePackage: (id: number) => void;
};

export default function CreatedPackagesContent({
  onSelectPackage,
  onDeletePackage,
  packages,
  selectedPackage,
}: CreatedPackagesContentProps) {
  return (
    <Each
      array={packages}
      renderItem={(packaging) => {
        const totalQuantity = packaging.selectedPallets?.reduce(
          (acc, pallet) => {
            return (acc += pallet?.quantityPerPallet || 0);
          },
          0
        );

        const isFull =
          packaging.selectedPallets?.length === packaging.quantityPerCargo;
        const hasPallets = !!packaging?.selectedPallets?.length;

        return (
          <React.Fragment key={packaging.id}>
            <Row
              className={cn([
                "w-full border rounded-lg h-full",
                selectedPackage?.id === packaging.id &&
                  "bg-gray-100 border-gray-400",
              ])}
            >
              <div
                className={cn([
                  "h-full rounded-l-lg w-3 ",
                  !isFull && "bg-gray-300",
                  hasPallets && "bg-yellow-500",
                  isFull && "bg-green-500",
                ])}
              />
              <Col className="gap-1 w-full p-2">
                <Text>
                  Transportadora:
                  <strong>
                    {" "}
                    {packaging.transporterName.split("_")[0]} -{" "}
                    {packaging.transporterName.split("_")[1]}
                  </strong>
                </Text>
                <Row className="gap-4">
                  <Text>
                    Data:{" "}
                    <strong>{dayjs(packaging.day).format("DD/MM/YYYY")}</strong>
                  </Text>
                  <Text>
                    Paletes por Carga:{" "}
                    <strong>{packaging.quantityPerCargo}</strong>
                  </Text>
                </Row>
                <Separator />
                <Text>
                  Paletes Selecionados {packaging.selectedPallets?.length || 0}{" "}
                  / {packaging.quantityPerCargo}
                </Text>
                <Text>
                  Quantidade Total: {quantityFormater(totalQuantity || 0)}
                </Text>
              </Col>
              <Col className="border-l gap-2 p-2">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => onSelectPackage(packaging)}
                >
                  <Eye size={20} color={TEXT_COLOR} />
                </Button>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => onDeletePackage(packaging.id)}
                >
                  <FaTrash size={20} color={TEXT_COLOR} />
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        );
      }}
    />
  );
}
