import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { PackagingSimulationFormType } from "./zod";
import { UseFormReturn } from "react-hook-form";
import ProductionSimulatorSelect from "@/components/Select/ProductionSimulatorSelect";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import Can from "@/components/Can";
import { Textarea } from "@/components/ui/textarea";
import { usePackagingPlanContext } from "@/pages/PlanoEmbalagem/context";

type PackagingSimulationFormProps = {
  form: UseFormReturn<PackagingSimulationFormType, any, undefined>;
  onSubmit: (data: PackagingSimulationFormType) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
  visibleObservation?: boolean;
};

export default function PackagingSimulationForm({
  form,
  button = { title: "Gerar" },
  onSubmit,
  visibleObservation = true,
}: PackagingSimulationFormProps) {
  const {
    packagingPlan: { FK_plano_producao },
  } = usePackagingPlanContext();

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name={"SK_simulacao"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Simulador Produto Acabado</FormLabel>
              <FormControl>
                <ProductionSimulatorSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                  filterByProductionPlan={{
                    fkProductionPlan: FK_plano_producao,
                  }}
                  filterByStatus="PUBLICADO"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Can
          condition={visibleObservation}
          onTrue={
            <FormField
              control={form.control}
              name="observacao"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Observação</FormLabel>
                  <FormControl>
                    <Textarea {...field} rows={4} className="resize-none" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          }
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
