import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingSimulatorKeys } from "./usePackagingSimulators";

export function usePackagingSimulatorStatusMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      SK_simulacao_embalagem: number;
      status: string;
    }) => {
      await instance.put("/planningbeer/packagingSimulator/status", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: packagingSimulatorKeys.all,
      });
    },
  });
}
