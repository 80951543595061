import { REQUIRED_FIELD } from "@/constants";
import z from "zod";

export const schema = z.object({
  file: z.object({}, { required_error: REQUIRED_FIELD }),
  FK_fabrica: z.string({ required_error: REQUIRED_FIELD }),
  vigencia: z.date({ required_error: REQUIRED_FIELD }),
  descricao: z.string().optional(),
  base: z.string({ required_error: REQUIRED_FIELD }),
});

export type FactoryResourceProductFormType = z.infer<typeof schema>;
