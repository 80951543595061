import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "./useBuildLoads";

export function useDeleteBuildLoadMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      await instance.delete("/planningbeer/buildLoad", {
        data: {
          id,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.all });
    },
  });
}
