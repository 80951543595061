import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingPlansKey } from "./usePackagingPlans";
import instance from "@/api/business";

export function usePackagingPlanDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      await instance.delete("/planningbeer/packagingPlan", {
        data: {
          id: id,
        },
      });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: packagingPlansKey.all }),
  });
}
