import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AssociateSupplierWithValuesType } from "../Steps/SupplierPackagingStep";

type SupplierPackagingType = {
  options: AssociateSupplierWithValuesType[];
  onValueChange: (value: string) => void;
  value: string;
};

export default function SupplierPackagingSelect({
  options,
  value,
  onValueChange,
}: SupplierPackagingType) {
  const formatedOptions = options.filter(
    (option, index, self) =>
      index ==
      self.findIndex((o) => o.codigo_fornecedor == option.codigo_fornecedor)
  );

  return (
    <Select onValueChange={onValueChange} value={value}>
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        {formatedOptions.map((option, index) => (
          <SelectItem key={index} value={option.codigo_fornecedor}>
            {option.codigo_fornecedor} - {option.nome_fornecedor}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
