import { Calendar as CalendarIcon } from "lucide-react";
import { ptBR } from "date-fns/locale";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import dayjs from "dayjs";

type DatePickerProps = {
  value?: Date;
  onChange: (date?: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

export function DatePicker({
  onChange,
  value,
  minDate,
  maxDate,
}: DatePickerProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !value && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? dayjs(value).format("DD/MM/YYYY") : ""}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Calendar
          mode="single"
          fromDate={minDate}
          toDate={maxDate}
          locale={ptBR}
          selected={value}
          onSelect={(selectedDate) => {
            onChange(selectedDate);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
