import { DatePicker } from "@/components/DatePicker";
import Row from "@/components/Row";
import FactorySelect from "@/components/Select/FactorySelect";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { REQUIRED_FIELD } from "@/constants";
import useFactories from "@/hooks/useFactories";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  handleSheetModel,
  SheetDataType,
  validateSheet,
  validateSheetRows,
} from "../../utils/sheet";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDecreaseInventoryMutation } from "../../hooks/useDecreaseInventoryMutation";
import dayjs from "dayjs";
import { validationError } from "@/utils/api";
import HelpDialog from "./HelpDialog";

const ACCEPTED_FILE_EXTENSIONS = "xlsx";

const schema = z.object({
  fkFactory: z.string({ required_error: REQUIRED_FIELD }),
  date: z.date({ required_error: REQUIRED_FIELD }),
  file: z.object({}, { required_error: REQUIRED_FIELD }),
});

type FormType = z.infer<typeof schema>;

export default function CreateDecreaseInventoryDialog() {
  useFactories();
  const [sheetData, setSheetData] = useState<SheetDataType[]>([]);
  const [open, setOpen] = useState(false);
  const { mutateAsync, isPending } = useDecreaseInventoryMutation();

  const form = useForm<FormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  async function handleExcelSheet(file: File) {
    const { sheetJson } = await validateSheet(file);
    const validated = validateSheetRows(sheetJson);

    return validated;
  }

  async function handleSubmit() {
    if (isPending) return;
    try {
      await mutateAsync({
        date: dayjs(form.getValues("date")).format("YYYY-MM-DD"),
        fkFactory: form.getValues("fkFactory"),
        sheet: sheetData,
      });
      form.reset();
      setSheetData([]);
      return handleDialog();
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <Button>Realizar Carga</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <FormField
              name="fkFactory"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Fábrica *</FormLabel>
                  <FormControl>
                    <FactorySelect
                      onValueChange={field.onChange}
                      value={String(field.value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="date"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Data *</FormLabel>
                  <FormControl>
                    <DatePicker onChange={field.onChange} value={field.value} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="file"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Carga *</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      accept=".xlsx"
                      onChange={async (event) => {
                        const file = event.target.files?.[0];
                        if (!file) return;
                        const fileExtension = file?.name?.split(".").pop();
                        if (fileExtension !== ACCEPTED_FILE_EXTENSIONS) {
                          toast.error(
                            "O arquivo deve ser um arquivo Excel (.xlsx)"
                          );
                          event.target.value = "";
                          field.onChange(null);
                          return;
                        }
                        try {
                          const sheetValues = await handleExcelSheet(file);
                          setSheetData(sheetValues);
                          return field.onChange(file);
                        } catch (error) {
                          toast.error(error as unknown as string);
                          event.target.value = "";
                          return;
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row justify="end" className="mt-4 gap-4">
              <HelpDialog />
              <Button
                onClick={handleSheetModel}
                variant="outline"
                type="button"
              >
                Baixar Modelo
              </Button>
              <Button type="submit" disabled={isPending}>
                Salvar
              </Button>
            </Row>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
