import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { ProductionPlanType } from "@/hooks/useProductionPlans";
import { useQueryClient } from "@tanstack/react-query";
import { productionPlanKeys } from "@/hooks/useProductionPlanMutation";
import Each from "../Each";
import dayjs from "dayjs";

type ProductionPlanSelectType = {
  data: Array<ProductionPlanType>;
};

export default function ProductionPlanSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const productionPlanData: ProductionPlanSelectType | undefined =
    queryClient.getQueryData(productionPlanKeys.all);
  const { data: productionPlan } = productionPlanData || {};

  if (!productionPlan) return null;
  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={productionPlan}
          renderItem={({
            SK_plano_producao,
            descricao,
            abreviatura_empresa,
            periodo_inicio,
            periodo_fim,
          }) => {
            return (
              <SelectItem value={String(SK_plano_producao)}>
                {SK_plano_producao} - {descricao}, {abreviatura_empresa},{" "}
                {dayjs(periodo_inicio).format("DD/MM/YYYY")} -{" "}
                {dayjs(periodo_fim).format("DD/MM/YYYY")}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
