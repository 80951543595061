import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

export function useDeleteLineValueMutation() {
  return useMutation({
    mutationFn: async () => {
      await instance.delete("/planningbeer/productionPlan/lineValue");
    },
    onSuccess: () => {
      return toast.success("Contenção realizada");
    },
    onError: () => {
      return toast.error("Erro ao realizar a contenção");
    },
  });
}
