import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePackagingSimulatorContext } from "../../context";
import { useProductionSimulatorLog } from "@/hooks/useProductionSimulatorLog";
import Each from "@/components/Each";
import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import dayjs from "dayjs";
import ForecastStatusCell from "@/pages/Estimativas/components/Cell/StatusCell";
import Row from "@/components/Row";
import Can from "@/components/Can";
import { IoInformationCircleOutline } from "react-icons/io5";
import { TEXT_COLOR } from "@/constants";

export default function HistoryDialog() {
  const {
    visibleDialog,
    dialogType,
    clear,
    simulator: { FK_simulacao: fkProductionSimulator },
  } = usePackagingSimulatorContext();
  const { data } = useProductionSimulatorLog(fkProductionSimulator);
  const { data: productionSimulatorLog = [] } = data || {};

  if (dialogType !== "history") return null;

  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent
        className={`${productionSimulatorLog.length == 0 ? "h-fit" : "h-full"} overflow-hidden`}
      >
        <Can
          condition={productionSimulatorLog.length == 0}
          onTrue={
            <Row align="center">
              <IoInformationCircleOutline
                size={110}
                color={TEXT_COLOR}
                className="absolute -top-4 -left-11 -z-20 opacity-20"
              />
              <div className="ml-10">
                <Text className="text-sm">
                  Ainda não houve mudança de status
                </Text>
              </div>
            </Row>
          }
          onFalse={
            <Col className="gap-4 mt-4 hover:overflow-y-scroll scroll-smooth">
              <Each
                array={productionSimulatorLog}
                renderItem={({ alterado_por, created_at, status }) => {
                  return (
                    <Row className="border rounded-lg">
                      <div className="mr-4 bg-gray-100 w-1/12 rounded-tl-lg rounded-bl-lg overflow-hidden" />
                      <Col className="gap-2 p-2">
                        <Text>
                          Alterado por: <strong>{alterado_por}</strong>
                        </Text>
                        <Text>
                          Data:{" "}
                          <strong>
                            {dayjs(created_at).format("DD/MM/YYYY hh:mm:ss")}
                          </strong>
                        </Text>
                        <Text>
                          Status: <ForecastStatusCell status={status} />
                        </Text>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Col>
          }
        />
      </DialogContent>
    </Dialog>
  );
}
