import instance from "@/api/business";
import { ResponseDataType } from "@/types";
import { useQuery } from "@tanstack/react-query";
import {
  PackagingSimulationMutationType,
  PackagingSimulationResponseType,
} from "./usePackagingSimulationMutation";

export const packagingSimulationKey = {
  show: ["seePackagingSimulation"] as const,
};

async function fetchProductionSimulation(
  data: PackagingSimulationMutationType
) {
  if (!data) return;
  const response = await instance.put<
    ResponseDataType<PackagingSimulationResponseType>
  >("/planningbeer/packagingSimulation", {
    ...data,
  });
  return response.data;
}

export function usePackagingSimulation(data: PackagingSimulationMutationType) {
  return useQuery({
    queryKey: packagingSimulationKey.show,
    queryFn: () => fetchProductionSimulation(data),
    gcTime: 0,
    staleTime: 0,
  });
}
