import Empty from "@/components/Empty";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";

export default function Initial() {
  return (
    <AuthenticatedLayout>
      <Empty />
    </AuthenticatedLayout>
  );
}
