import Each from "@/components/Each";
import Text from "@/components/Text/Text";
import { Separator } from "@/components/ui/separator";
import React, { useMemo } from "react";
import SelectedPalletRow from "../Row/SelectedPalletRow";
import { CreatedPackagesType, SelectedPalletType } from "../Modal/LoadPlanner";
import dayjs from "dayjs";
import { PackagingType } from "@/hooks/usePackagings";
import Col from "@/components/Col";
import Row from "@/components/Row";
import { quantityFormater } from "@/utils/number";
import { MdPallet } from "react-icons/md";
import { TEXT_COLOR } from "@/constants";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { AiOutlineShop } from "react-icons/ai";

type SelectedPalletContentProps = {
  packagings: PackagingType[];
  selectedPackage?: CreatedPackagesType;
  onRemovePallet: (selected: SelectedPalletType, id?: number) => void;
};

type TotalPerCompositionType = {
  [composition: string]: {
    total: number;
    composition: string;
    quantitySelected: number;
  };
};

type GroupedSupplierType = {
  [supplier: string]: {
    name: string;
    count: number;
    totalQuantity: number;
    paletes: SelectedPalletType[];
  };
};

function groupBySupplier(data: SelectedPalletType[]) {
  return data.reduce(
    (acc, item) => {
      const { supplier, supplierName, quantityPerPallet = 0 } = item;

      if (!acc[supplier]) {
        acc[supplier] = {
          name: supplierName,
          paletes: [],
          count: 0,
          totalQuantity: 0,
        };
      }

      acc[supplier].paletes.push(item);
      acc[supplier].count += 1;
      acc[supplier].totalQuantity += quantityPerPallet;

      return acc;
    },
    {} as Record<
      string,
      {
        name: string;
        paletes: SelectedPalletType[];
        count: number;
        totalQuantity: number;
      }
    >
  );
}

export function SelectedPalletContent({
  packagings,
  selectedPackage,
  onRemovePallet,
}: SelectedPalletContentProps) {
  const totalPerComposition: TotalPerCompositionType =
    selectedPackage?.selectedPallets?.reduce((acc, curr) => {
      const prevTotal = acc[curr.composition]?.total || 0;
      const prevQuantitySelected = acc[curr.composition]?.quantitySelected || 0;
      const newTotal = prevTotal + curr.quantityPerPallet;

      return {
        ...acc,
        [curr.composition]: {
          total: newTotal,
          composition: curr.composition,
          quantitySelected: prevQuantitySelected + 1,
        },
      };
    }, {}) || {};

  const totalQuantity = Object.values(totalPerComposition).reduce(
    (acc, curr) => {
      return acc + curr.total;
    },
    0
  );

  const groupedBySupplier: GroupedSupplierType = useMemo(() => {
    return groupBySupplier(selectedPackage?.selectedPallets || []);
  }, [selectedPackage]);

  return (
    <React.Fragment>
      <div className="flex flex-col gap-2 px-4 mt-2">
        <Row className="gap-6">
          <Text style={{ fontSize: 15, color: TEXT_COLOR }}>
            Transportadora:{" "}
            <strong>
              {selectedPackage?.transporterName?.replace("_", " - ")}
            </strong>
          </Text>
          <Text style={{ fontSize: 15, color: TEXT_COLOR }}>
            Data:{" "}
            <strong>
              {selectedPackage
                ? dayjs(selectedPackage?.day).format("DD/MM/YYYY")
                : null}
            </strong>
          </Text>
        </Row>
        <Row className="gap-2">
          <Text>
            Paletes por Carga:{" "}
            <strong>{selectedPackage?.quantityPerCargo}</strong>
          </Text>
          <Text>
            Paletes Selecionados:{" "}
            <strong>{selectedPackage?.selectedPallets?.length || 0}</strong>
            {" / "}
            <strong>{selectedPackage?.quantityPerCargo}</strong>
          </Text>
          <Text>
            Quantidade Total: <strong>{quantityFormater(totalQuantity)}</strong>
          </Text>
        </Row>
      </div>
      <Separator />
      <Col>
        <Each
          array={Object.values(totalPerComposition)}
          renderItem={(values) => {
            return (
              <Row align="center" className="ml-4">
                <MdPallet size={22} color={TEXT_COLOR} />
                <Row className="ml-2 py-1 w-full gap-6">
                  <Text>
                    Composição: <strong>{values.composition}</strong>
                  </Text>
                  <Text>
                    Paletes Selecionados:{" "}
                    <strong>{values.quantitySelected}</strong>
                  </Text>
                  <Text>
                    Quantidade:{" "}
                    <strong>{quantityFormater(values.total)}</strong>
                  </Text>
                </Row>
              </Row>
            );
          }}
        />
      </Col>
      <Separator />
      <div className="max-h-full overflow-y-scroll">
        <Each
          array={Object.values(groupedBySupplier) || []}
          renderItem={({ name, paletes, count, totalQuantity }) => {
            return (
              <>
                <Accordion type="single" collapsible>
                  <AccordionItem value={name}>
                    <AccordionTrigger className="mt-2">
                      <Col className="items-start w-full">
                        <Row className="w-full" align="center">
                          <AiOutlineShop
                            size={22}
                            color={TEXT_COLOR}
                            className="ml-4"
                          />
                          <Text className="text-base ml-4 font-bold">
                            {name}
                          </Text>
                        </Row>
                        <Row className="ml-4 mt-1 gap-2">
                          <Text>
                            Paletes Selecionados: <strong>{count}</strong>
                          </Text>
                          <Text className="text-sm">
                            Quantidade Total: {quantityFormater(totalQuantity)}
                          </Text>
                        </Row>
                        <Separator className="mt-2" />
                      </Col>
                    </AccordionTrigger>
                    <AccordionContent>
                      <Each
                        array={paletes}
                        renderItem={(palete) => {
                          return (
                            <SelectedPalletRow
                              {...palete}
                              packagings={packagings}
                              onRemove={() => {
                                onRemovePallet(palete, selectedPackage?.id);
                              }}
                            />
                          );
                        }}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </>
            );
          }}
        />
      </div>
    </React.Fragment>
  );
}
