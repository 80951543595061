export const DEMAND_PLANNING_ST = "PREV DEMANDA";
export const ACCUMULATE_SALE_ST = "VENDA ACUMULADA";
export const SD_INICIAL_ST = "SD INICIAL";
export const SD_FINAL_ST = "SD FINAL";
export const LINE_CELL = "LINHA";
export const AVERAGE_SALE_ST = "VENDA MÉDIA";
export const GRID_SALE = "VENDA GRADE";
export const REAL_PRODUCTION_ST = "PROD REAL";
export const ACCUMULATED_PRODUCION_ST = "PROD ACUM";
export const SEGREGATED_GRID_SALE_ST = "VENDA GRADE SEGREGADA";
export const SEGREGATED_REAL_PRODUCTION_ST = "PROD REAL SEGREGADA";
export const REAL_PRODUCTION_TOTAL_ST = "PROD REAL TOTAL";
export const GRID_SALE_TOTAL_ST = "VENDA GRADE TOTAL";

const CELL_COLORS = {
  PURPLE: "#cc99ff",
  LIGHT_PURPLE: "#ccccff",
  LIGHT_BLUE: "#ADE8F4",
  LIGHT_YELLOW: "#fffcb2",
};

export const PRODUCTION_SIMULATION_CELL_COLORS = {
  [SD_INICIAL_ST]: CELL_COLORS.PURPLE,
  [DEMAND_PLANNING_ST]: CELL_COLORS.LIGHT_BLUE,
  [ACCUMULATE_SALE_ST]: CELL_COLORS.LIGHT_BLUE,
  [GRID_SALE]: CELL_COLORS.LIGHT_BLUE,
  [GRID_SALE_TOTAL_ST]: CELL_COLORS.LIGHT_BLUE,
  [SEGREGATED_GRID_SALE_ST]: CELL_COLORS.LIGHT_BLUE,
  [AVERAGE_SALE_ST]: CELL_COLORS.LIGHT_YELLOW,
  [REAL_PRODUCTION_ST]: CELL_COLORS.LIGHT_PURPLE,
  [ACCUMULATED_PRODUCION_ST]: CELL_COLORS.LIGHT_PURPLE,
  [SEGREGATED_REAL_PRODUCTION_ST]: CELL_COLORS.LIGHT_PURPLE,
  [REAL_PRODUCTION_TOTAL_ST]: CELL_COLORS.LIGHT_PURPLE,
  [SD_FINAL_ST]: CELL_COLORS.PURPLE,
};

export const COLORS = {
  transparent: "transparent",
  green: "green",
  red: "red",
  orange: "orange",
  blue: "blue",
};
