import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { TEXT_COLOR } from "@/constants";
import { useState } from "react";
import { FaListCheck } from "react-icons/fa6";

type SelectMultiplePalletDropdownProps = {
  maxPallets: number;
  onAddPallets(pallets: number): void;
};

export default function SelectMultiplePalletDropdown({
  maxPallets,
  onAddPallets,
}: SelectMultiplePalletDropdownProps) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu onOpenChange={() => setOpen((prev) => !prev)} open={open}>
      <DropdownMenuTrigger>
        <Button size="icon" className="ml-3" variant="outline">
          <FaListCheck size={22} color={TEXT_COLOR} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-3">
        <Text>Quantidade a adicionar</Text>
        <Input
          type="number"
          max={maxPallets}
          min={0}
          value={value}
          onChange={(e) => {
            let inputValue = Number(e.currentTarget.value);
            if (inputValue > maxPallets) {
              inputValue = maxPallets;
            } else if (inputValue < 0) {
              inputValue = 0;
            }
            setValue(inputValue);
          }}
        />

        <Text className="mt-1 text-sm italic">Limite: {maxPallets}</Text>
        <Button
          className="mt-4 w-full"
          size="sm"
          onClick={() => onAddPallets(value)}
        >
          Adicionar
        </Button>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
