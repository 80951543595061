import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  cargoModelKey,
  CargoModelType,
} from "@/pages/ModeloCarga/hooks/useCargoModels";
import Each from "../Each";
import Text from "../Text/Text";
import { SelectProps } from "@radix-ui/react-select";

type CargoModelData = {
  data: Array<CargoModelType>;
};

type CargoModelSelectProps = {
  keyValue?: (data: CargoModelType) => string;
} & SelectProps;

export const DEFAULT_CARGO_MODEL = {
  id: "padrao",
  value: "25",
  concat: () => {
    DEFAULT_CARGO_MODEL.id + "_" + DEFAULT_CARGO_MODEL.value;
  },
};

export default function CargoModelSelect(props: CargoModelSelectProps) {
  const queryClient = useQueryClient();
  const data: CargoModelData | undefined = queryClient.getQueryData(
    cargoModelKey.all
  );
  const { data: cargoModel } = data || {};
  if (!cargoModel) return null;

  return (
    <Select onValueChange={props.onValueChange} value={props.value}>
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={cargoModel}
          renderItem={(item) => {
            return (
              <SelectItem
                value={
                  props.keyValue
                    ? props.keyValue(item)
                    : String(item.SK_modelo_carga) +
                      "_" +
                      String(item.quantidade_palete)
                }
              >
                <Text color="black">
                  {item.nome}, <strong>{item.quantidade_palete}</strong>
                </Text>
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
