import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import React from "react";
import { FiltersType } from "../Modal/LoadPlanner";
import Can from "@/components/Can";

export default function SelectedFilters(filters: FiltersType) {
  return (
    <React.Fragment>
      <Row className="gap-1" align="center">
        <Text>Filtros Aplicados:</Text>
        <Can
          condition={
            filters.initialPeriod?.length != 0 ||
            filters.finalPeriod?.length != 0
          }
          onTrue={
            <Text
              className="p-1 px-2 bg-gray-500 rounded-full text-sm"
              color="white"
            >
              Período: {filters.initialPeriod} - {filters.finalPeriod}
            </Text>
          }
        />
        <Can
          condition={(filters.packagings?.length ?? 0) != 0}
          onTrue={
            <div className="min-w-[200px] w-fit max-w-full overflow-hidden whitespace-nowrap text-ellipsis">
              <p className="text-ellipsis overflow-hidden whitespace-nowrap text-sm bg-gray-400 rounded-full px-2 p-1 text-white">
                Embalagens: {filters?.packagings?.join(",")}
              </p>
            </div>
          }
        />
      </Row>
    </React.Fragment>
  );
}
