import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

type BaseSelectProps = {
  showGrouped?: boolean;
} & SelectProps;

export default function BaseSelect(props: BaseSelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        {props.showGrouped && <SelectItem value="GROUPED">Agrupado</SelectItem>}
        <SelectItem value="BASE2">Estratégico</SelectItem>
        <SelectItem value="BASE1">Padrão</SelectItem>
      </SelectContent>
    </Select>
  );
}
