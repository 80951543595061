import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreateDecreaseInventoryDialog from "./components/Dialog/CreateDecraseInventoryDialog";
import {
  DecreaseInventoryType,
  useDecreaseInventories,
} from "./hooks/useDecreaseInventories";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import dayjs from "dayjs";
import Table from "@/components/NewTable";
import { quantityFormater } from "@/utils/number";
import ActionCell from "@/components/Cell/ActionCell";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import EditDecreaseInventoryDialog from "./components/Dialog/EditDecreaseInventoryDialog";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useDecreaseInventoryContext } from "./context";
import useDecreaseInventoryDeleteMutation from "./hooks/useDecreaseInventoryDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import useProducts from "@/hooks/useProducts";
import { getPermissionCookie } from "@/utils/cookies";
import { useMemo } from "react";

const columnHelper = createColumnHelper<DecreaseInventoryType>();

type DecreaseInventoryTableType = TableData<DecreaseInventoryType>;

export default function DecreaseInventory() {
  useProducts();
  const { mutateAsync, isPending } = useDecreaseInventoryDeleteMutation();
  const { decreaseInventory, clear, visibleDialog, dialogType } =
    useDecreaseInventoryContext();
  const { data } = useDecreaseInventories();
  const { data: decreaseInventories = [] } = data || {};
  const permission = getPermissionCookie();

  const columns = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor("produto", {
        header: "Produto",
        id: "produto",
        size: 130,
      }),
      columnHelper.accessor("descricao", {
        header: "Descrição",
        id: "descricao",
        size: 300,
      }),
      columnHelper.accessor("quantidade", {
        header: "Quantidade",
        id: "quantidade",
        size: 150,
        cell: ({ getValue }) => {
          return quantityFormater(getValue() || 0);
        },
      }),
      columnHelper.accessor("abreviatura_empresa", {
        header: "Fábrica",
        id: "abreviatura_empresa",
        size: 150,
      }),
      columnHelper.accessor("dia", {
        header: "Data",
        id: "data",
        size: 120,
        cell: ({ getValue }) => {
          return dayjs(getValue()).format("DD/MM/YYYY");
        },
      }),
      columnHelper.accessor("motivo", {
        header: "Motivo",
        id: "motivo",
        size: 300,
      }),
    ];

    if (permission == "logistics") {
      const logiticsColumns = [
        columnHelper.accessor("SK_baixa_inventario", {
          header: "ID",
          id: "SK_baixa_inventario",
          size: 90,
        }),
        columnHelper.display({
          id: "Ações",
          header: "Ações",
          size: 100,
          cell: ({ row }) => {
            return <ActionCell items={<ActionDropdown {...row.original} />} />;
          },
        }),
        ...defaultColumns,
        columnHelper.accessor("created_by", {
          header: "Criado por",
          id: "created_by",
          size: 250,
        }),
        columnHelper.accessor("created_at", {
          header: "Criado em",
          id: "created_at",
          cell: ({ getValue }) => {
            return dayjs(getValue()).format("DD/MM/YYYY HH:mm:ss");
          },
        }),
      ];

      return logiticsColumns;
    }

    return defaultColumns;
  }, [permission]);

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync(decreaseInventory.SK_baixa_inventario);
      toast.success("Baixa excluída com sucesso!");
      return clear();
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateDecreaseInventoryDialog />
      </Row>
      <Table<DecreaseInventoryTableType>
        columns={columns}
        data={decreaseInventories}
        meta={{
          layout: "stretch",
        }}
      />
      <EditDecreaseInventoryDialog />
      <DeleteDialogAlert
        alertDialogTitle={`Deseja excluir a baixa "${decreaseInventory?.SK_baixa_inventario}"? Essa ação é irreversível.`}
        type={dialogType}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        onConfirm={handleDelete}
      />
    </AuthenticatedLayout>
  );
}
