import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { TEXT_COLOR } from "@/constants";
import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardDoubleArrowRight, MdPallet } from "react-icons/md";
import CreatedPackagesModal from "./CreatedPackagesModal";
import CreatedPackagesContent from "../../Content/CreatedPackagesContent";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { usePackagingPlanContext } from "@/pages/PlanoEmbalagem/context";
import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { groupBySupplier } from "@/pages/PlanoEmbalagem/utils/buildLoadUtils";
import { usePackagingSupplierGrouped } from "@/pages/PlanoEmbalagem/hooks/usePackagingSupplierGrouped";
import Each from "@/components/Each";
import LoadPackagingContent from "../../Content/LoadPackagingContent";
import usePackagings from "@/hooks/usePackagings";
import Can from "@/components/Can";
import { toast } from "sonner";
import { SelectedPalletContent } from "../../Content/SelectedPalletContent";
import { Separator } from "@/components/ui/separator";
import FilterLoadPlannerPackageDropdown, {
  FormSchema,
} from "../../Dropdown/FilterLoadPlannerPackageDropdown";
import { isUndefined } from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import SelectedFilters from "../../Content/SelectedFilters";
dayjs.extend(isBetween);

export type SuppliersPackageType = {
  fkSupplier: string;
  supplierName: string;
  cargoModel?: string;
  palletPerCargo?: number;
  cargoName?: string;
  dates: {
    day: string;
    items: PackagingSupplierCellValueType[];
  }[];
};

export type SelectedPalletType = {
  composition: string;
  pallet: string;
  day: string;
  supplier: string;
  supplierName: string;
  type?: string;
  quantityPerPallet?: number;
};

export type CreatedPackagesType = {
  id: number;
  fkTransporter: string;
  fkCargoModel: string;
  day: string;
  quantityPerCargo: number;
  transporterName: string;
  selectedPallets?: SelectedPalletType[];
};

export type HandleCreatePackagesProps = CreatedPackagesType & {
  quantityToCreate: number;
};

export type FiltersType = {
  initialPeriod?: string;
  finalPeriod?: string;
} & Pick<FormSchema, "packagings">;

export function filterPackagingSupplierByDate(
  form: FormSchema,
  valuesToFilter: PackagingSupplierCellValueType[]
) {
  const initialPeriodFormValue = form.initialPeriod || undefined;
  const finalPeriodFormValue = form.finalPeriod || undefined;
  const initialPeriod = isUndefined(initialPeriodFormValue)
    ? undefined
    : dayjs(initialPeriodFormValue);
  const finalPeriod = isUndefined(finalPeriodFormValue)
    ? undefined
    : dayjs(finalPeriodFormValue);

  if (initialPeriod && finalPeriod) {
    valuesToFilter = valuesToFilter.filter((value) => {
      const date = dayjs(value?.dia);
      return date.isBetween(initialPeriod, finalPeriod, "day", "[]");
    });
  }

  return valuesToFilter;
}

export function filterPackagingSupplierByComposition(
  compositions: string[],
  valuesToFilter: PackagingSupplierCellValueType[]
) {
  if (compositions?.length > 0) {
    valuesToFilter = valuesToFilter.filter((value) => {
      return compositions.includes(value.composicao);
    });

    return valuesToFilter;
  }
  return valuesToFilter;
}

export function generatePalletId({
  composition,
  day,
  fkPackagingSupplier,
  index,
}: {
  composition: string;
  fkPackagingSupplier: string;
  day: string;
  index: number;
}) {
  return (
    String(composition) + String(fkPackagingSupplier) + String(day) + index
  );
}

function checkPalletQuantityLimit(
  quantityPerCargo: number,
  quantitySelected: number
) {
  if (quantitySelected >= quantityPerCargo) return true;
  return false;
}

export default function LoadPlannerModal() {
  const { data } = usePackagingSupplierGrouped();
  const { data: packagingSupplierGrouped = [] } = data || {};
  const { data: packagingsData } = usePackagings();
  const { data: packagings = [] } = packagingsData || {};
  const {
    packagingPlan: { valores_fornecedores: packagingSuppliers },
  } = usePackagingPlanContext();
  //prettier-ignore
  const [suppliersPackage, setSuppliersPackage] = useState<SuppliersPackageType[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<CreatedPackagesType>();
  const [packages, setPackages] = useState<Array<CreatedPackagesType>>([]);
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState<FiltersType>({
    initialPeriod: undefined,
    finalPeriod: undefined,
    packagings: [],
  });

  function getSelectedPallets() {
    return packages.flatMap((packaging) => {
      return (
        packaging.selectedPallets?.map((selectedPallet) => {
          return selectedPallet.pallet;
        }) || []
      );
    });
  }

  function handleCreatePackages(data: HandleCreatePackagesProps) {
    setPackages((prev) => [
      ...prev,
      ...Array.from({ length: data.quantityToCreate }).map((_, index) => ({
        ...data,
        id: Date.now() + Math.floor(Math.random() * 1000) + index,
      })),
    ]);
  }

  function handleSelectPackage(data: CreatedPackagesType) {
    if (selectedPackage?.id === data.id) return setSelectedPackage(undefined);
    setSelectedPackage(data);
  }

  function handleSelectPallet(pallet: SelectedPalletType, cargoId?: number) {
    if (!cargoId) return;
    setPackages((prev) => {
      const newPackages = prev.map((packaging) => {
        if (packaging.id === cargoId) {
          const totalSelectedPallets = packaging.selectedPallets?.length || 0;
          const isPalletQuantityLimitExceeded = checkPalletQuantityLimit(
            packaging.quantityPerCargo,
            totalSelectedPallets
          );

          if (isPalletQuantityLimitExceeded) {
            toast.error(
              "Quantidade de paletes selecionados excedeu o limite por carga"
            );
            return packaging;
          }

          const existPallet = packaging.selectedPallets?.findIndex(
            (selectedPallet) => {
              return selectedPallet.pallet == pallet.pallet;
            }
          );
          if (existPallet == -1 || existPallet === undefined) {
            return {
              ...packaging,
              selectedPallets: [
                ...(packaging.selectedPallets || []),
                { ...pallet },
              ],
            };
          }
          return {
            ...packaging,
            selectedPallets: packaging.selectedPallets?.filter(
              (selectedPallet) => selectedPallet.pallet !== pallet.pallet
            ),
          };
        }
        return packaging;
      });
      return newPackages;
    });

    setSelectedPackage((prev) => {
      if (!prev) return prev;
      const isPalletQuantityLimitExceeded = checkPalletQuantityLimit(
        prev.quantityPerCargo,
        prev.selectedPallets?.length || 0
      );
      if (isPalletQuantityLimitExceeded) {
        return prev;
      }

      const existPrevPallet = prev.selectedPallets?.findIndex(
        (selectedPallet) => selectedPallet.pallet === pallet.pallet
      );
      if (existPrevPallet === -1 || existPrevPallet === undefined) {
        return {
          ...prev,
          selectedPallets: [...(prev?.selectedPallets || []), { ...pallet }],
        };
      }
      return {
        ...prev,
        selectedPallets: prev?.selectedPallets?.filter(
          (selectedPallet) => selectedPallet.pallet !== pallet.pallet
        ),
      };
    });
  }

  function handleRemovePallet(pallet: SelectedPalletType, cargoId?: number) {
    if (!cargoId) return;
    setPackages((prev) => {
      const newPackages = prev.map((packaging) => {
        if (packaging.id === cargoId) {
          return {
            ...packaging,
            selectedPallets: packaging.selectedPallets?.filter(
              (selectedPallet) => selectedPallet.pallet !== pallet.pallet
            ),
          };
        }
        return packaging;
      });
      return newPackages;
    });

    setSelectedPackage((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        selectedPallets: prev.selectedPallets?.filter(
          (selectedPallet) => selectedPallet.pallet !== pallet.pallet
        ),
      };
    });
  }

  function handleDeletePackage(id: number) {
    setPackages((prev) => prev.filter((packaging) => packaging.id !== id));
    setSelectedPackage(undefined);
  }

  function handleFilter(filter: FormSchema) {
    const packagingSuppliersValue = JSON.parse(
      packagingSuppliers || "[]"
    ) as PackagingSupplierCellValueType[];
    let filteredValues = packagingSuppliersValue;

    filteredValues = filterPackagingSupplierByDate(filter, filteredValues);
    filteredValues = filterPackagingSupplierByComposition(
      filter.packagings || [],
      filteredValues
    );

    setSuppliersPackage(
      groupBySupplier(filteredValues, packagingSupplierGrouped)
    );
    setVisible(false);
    setFilters({
      initialPeriod: dayjs(filter.initialPeriod).format("DD/MM/YYYY") || "",
      finalPeriod: dayjs(filter.finalPeriod).format("DD/MM/YYYY") || "",
      packagings: filter.packagings || [],
    });
  }

  function handleClearFilter() {
    setSuppliersPackage(
      groupBySupplier(
        JSON.parse(
          packagingSuppliers || "[]"
        ) as PackagingSupplierCellValueType[],
        packagingSupplierGrouped
      )
    );
    setVisible(false);
    setFilters({
      initialPeriod: "",
      finalPeriod: "",
      packagings: [],
    });
  }

  useEffect(() => {
    if (!packagingSuppliers || !packagingSupplierGrouped) return;
    const packagingSuppliersValue = JSON.parse(
      packagingSuppliers || "[]"
    ) as PackagingSupplierCellValueType[];
    setSuppliersPackage(
      groupBySupplier(packagingSuppliersValue, packagingSupplierGrouped)
    );
  }, [packagingSuppliers, data]);

  useEffect(() => {
    return () => {
      setPackages([]);
      setSuppliersPackage([]);
      setSelectedPackage(undefined);
      setVisible(false);
      setFilters({
        initialPeriod: "",
        finalPeriod: "",
        packagings: [],
      });
    };
  }, []);

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="link">
          <Row>
            <MdPallet color={TEXT_COLOR} size={20} />
            <Text className="ml-1">Gerar Cargas</Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-full w-[calc(100vw-40px)] h-screen flex p-0 gap-0">
        <Sheet>
          <SheetTrigger>
            <Button variant="outline" size="icon" className="m-1">
              <MdOutlineKeyboardDoubleArrowRight size={30} color={TEXT_COLOR} />
            </Button>
          </SheetTrigger>
          <SheetContent className="overflow-y-scroll flex flex-col" side="left">
            <CreatedPackagesModal onCreatedPackages={handleCreatePackages} />
            <CreatedPackagesContent
              packages={packages}
              selectedPackage={selectedPackage}
              onDeletePackage={handleDeletePackage}
              onSelectPackage={handleSelectPackage}
            />
          </SheetContent>
        </Sheet>
        <Col className="h-full w-7/12  border-l border-r-2 max-h-full overflow-y-scroll">
          <Can
            condition={!!selectedPackage}
            onTrue={
              <React.Fragment>
                <Row
                  className="py-2 ml-2"
                  align="center"
                  justify="space-between"
                >
                  <SelectedFilters {...filters} />
                  <FilterLoadPlannerPackageDropdown
                    visible={visible}
                    handleClose={() => setVisible(!visible)}
                    onFilter={handleFilter}
                    onClear={handleClearFilter}
                  />
                </Row>
                <Separator />
                <Each
                  array={suppliersPackage}
                  renderItem={({ dates, fkSupplier, supplierName }) => {
                    return (
                      <LoadPackagingContent
                        packagings={packagings}
                        palletsPerCargo={selectedPackage?.quantityPerCargo || 0}
                        selectedPalletsQuantity={
                          selectedPackage?.selectedPallets?.length || 0
                        }
                        dates={dates}
                        supplierFk={fkSupplier}
                        supplierName={supplierName}
                        selectedPallets={getSelectedPallets()}
                        onCheckPallete={(value) => {
                          handleSelectPallet(
                            { ...value, supplierName },
                            selectedPackage?.id
                          );
                        }}
                      />
                    );
                  }}
                />
              </React.Fragment>
            }
          />
        </Col>
        <Col className="h-full w-9/12 gap-1">
          <Can
            condition={!!selectedPackage}
            onTrue={
              <SelectedPalletContent
                selectedPackage={selectedPackage}
                packagings={packagings}
                onRemovePallet={(selected, id) =>
                  handleRemovePallet(selected, id)
                }
              />
            }
          />
        </Col>
      </DialogContent>
    </Dialog>
  );
}
