import { DialogProps } from "@/types";
import Text from "../Text/Text";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import Row from "../Row";
import { useDeleteLineValueMutation } from "@/hooks/useDeleteLineValueMutation";
import { MdContentCopy } from "react-icons/md";
import { TEXT_COLOR } from "@/constants";

export default function DeleteLineModal({ handleClose, visible }: DialogProps) {
  const { mutateAsync, isPending } = useDeleteLineValueMutation();

  async function handleDelete() {
    if (isPending) return;
    await mutateAsync();
    handleClose();
    window.location.reload();
  }

  return (
    <Dialog onOpenChange={handleClose} open={visible}>
      <DialogTrigger>
        <Row align="center" className="ml-2 mt-1">
          <MdContentCopy color={TEXT_COLOR} size={14} />
          <Text className="mt-1 ml-1 text-sm">Contenção</Text>
        </Row>
      </DialogTrigger>
      <DialogContent>
        <Text className="font-bold">
          Deseja realizar contenção? Essa ação é irreversível.
        </Text>
        <Row justify="end" className="gap-4">
          <Button variant="outline" onClick={handleClose}>
            <Text>Cancelar</Text>
          </Button>
          <Button onClick={handleDelete}>Confirmar</Button>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
