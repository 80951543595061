import { DEFAULT_CARGO_MODEL } from "@/components/Select/CargoModelSelect";
import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { useState } from "react";

export type GetProgrammedCollectionCellValueProps = {
  day: string;
  composition: string;
};

export function usePackagingSupplier() {
  const [packagingSupplierCellValue, setPackagingSupplierCellValue] = useState<
    Array<PackagingSupplierCellValueType>
  >([]);

  function handlePackagingSupplierCellValue(
    cellData: PackagingSupplierCellValueType | PackagingSupplierCellValueType[]
  ) {
    if (Array.isArray(cellData)) {
      return setPackagingSupplierCellValue(cellData);
    }
    setPackagingSupplierCellValue((prev) => {
      const newPackagingSupplierCellValue = prev.filter(
        (cell) =>
          cell.FK_embalagem_fornecedor !== cellData.FK_embalagem_fornecedor ||
          cell.dia !== cellData.dia ||
          cell.FK_fabrica !== cellData.FK_fabrica
        // cell.FK_simulacao_producao !== cellData.FK_simulacao_producao
      );
      return [...newPackagingSupplierCellValue, cellData];
    });
  }

  function getPackagingSupplierCellValue(
    data: Omit<PackagingSupplierCellValueType, "valor">
  ) {
    const {
      valor,
      quantidade_palete_carga = DEFAULT_CARGO_MODEL.value,
      FK_modelo_carga = DEFAULT_CARGO_MODEL.id,
    } = packagingSupplierCellValue.find(
      (cell) =>
        cell.FK_embalagem_fornecedor == data.FK_embalagem_fornecedor &&
        cell.dia == data.dia &&
        cell.FK_fabrica == data.FK_fabrica
      // cell.FK_simulacao_producao == data.FK_simulacao_producao
    ) || {
      valor: "0",
      quantidade_palete_carga: DEFAULT_CARGO_MODEL.value,
      FK_modelo_carga: DEFAULT_CARGO_MODEL.id,
    };

    return {
      value: valor,
      cargoQuantityPallet: quantidade_palete_carga,
      fkCargoModel: FK_modelo_carga,
    };
  }

  function getProgrammedCollectionCellValue({
    composition,
    day,
  }: GetProgrammedCollectionCellValueProps) {
    return packagingSupplierCellValue.reduce((acc, cell) => {
      if (cell.dia == day && cell.composicao == composition) {
        return acc + Number(cell.valor);
      }
      return acc;
    }, 0);
  }

  function clear() {
    setPackagingSupplierCellValue([]);
  }

  return {
    packagingSupplierCellValue,
    handlePackagingSupplierCellValue,
    getPackagingSupplierCellValue,
    getProgrammedCollectionCellValue,
    clear,
  };
}
