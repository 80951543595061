import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type PackagingPlanOldTypeResponse = {
  sk_plano_embalagem_old: number;
  observacao?: string;
  data: string;
  revisao: string;
  edicao: boolean;
  evento?: string;
  deletado?: string;
  valores_linhas: {
    dia: string;
    valor: string;
    FK_fabrica: number;
    composicao: string;
    FK_simulacao_embalagem: number;
    FK_embalagem_fornecedor: number;
    SK_simulacao_embalagem_fornecedor: number;
    quantidade_palete_carga: string;
    FK_modelo_carga: string;
  }[];
};

const packagingPlanOldKeys = {
  show: ["packagingPlanOld"] as const,
};

async function fetchPackagingPlanOld(id: number) {
  if (!id) return;
  const response = await instance.post<
    ApiResponse<PackagingPlanOldTypeResponse>
  >("/planningbeer/packagingPlan/old", {
    skPackagingPlan: id,
  });

  return response.data;
}

export function usePackagingPlanOld(id: number) {
  return useQuery({
    queryKey: packagingPlanOldKeys.show,
    queryFn: () => fetchPackagingPlanOld(id),
    staleTime: 0,
    gcTime: 0,
  });
}
