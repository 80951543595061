import Text from "../Text/Text";

type Props = {
  revisao: string;
};

export function VersionBadge({ revisao }: Props) {
  return (
    <Text color="black" className="bg-gray-300 px-2 py-1 rounded">
      v{revisao}
    </Text>
  );
}
