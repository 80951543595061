import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import { CargoModelType } from "../../hooks/useCargoModels";
import { useCargoModelContext } from "../../context";

export default function ActionDropdown(props: CargoModelType) {
  const { handleDialog, handleDialogType, handleCargoModel } =
    useCargoModelContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleCargoModel({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleCargoModel({ ...props });
  }
  return (
    <>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </>
  );
}
