import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import "./i18n";

const CACHE_TIME = 3600000; // 1 Hora
const STALE_TIME = 3600000; // 1 Hora

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchInterval: CACHE_TIME,
      staleTime: STALE_TIME,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools position="left" /> */}
    </QueryClientProvider>
  </React.StrictMode>
);
