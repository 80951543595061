import { PackagingType } from "@/hooks/usePackagings";
import {
  SelectedPalleteType,
  SuppliersPackageType,
} from "../components/Steps/BuildLoad/LoadPackaging";
import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { PackagingSupplierGroupedType } from "../hooks/usePackagingSupplierGrouped";
import { isUndefined, split } from "lodash";
import dayjs from "dayjs";
import { UseFormReturn } from "react-hook-form";
import { BuildLoadFormType } from "../components/Form/BuildLoad/BuildLoadForm";

export function filterPackagingSupplierByDate(
  form: UseFormReturn<BuildLoadFormType>,
  valuesToFilter: PackagingSupplierCellValueType[]
) {
  const initialPeriodFormValue = form.getValues("periodo_inicio") || undefined;
  const finalPeriodFormValue = form.getValues("periodo_fim") || undefined;
  const initialPeriod = isUndefined(initialPeriodFormValue)
    ? undefined
    : dayjs(initialPeriodFormValue);
  const finalPeriod = isUndefined(finalPeriodFormValue)
    ? undefined
    : dayjs(finalPeriodFormValue);

  if (initialPeriod && finalPeriod) {
    valuesToFilter = valuesToFilter.filter((value) => {
      const date = dayjs(value.dia);
      return date.isBetween(initialPeriod, finalPeriod, "day", "[]");
    });
  }

  return valuesToFilter;
}

export function filterPackagingSupplierByComposition(
  compositions: string[],
  valuesToFilter: PackagingSupplierCellValueType[]
) {
  if (compositions?.length > 0) {
    valuesToFilter = valuesToFilter.filter((value) => {
      return compositions.includes(value.composicao);
    });

    return valuesToFilter;
  }
  return valuesToFilter;
}

export function isSelectedPallete(
  selectedPallete: SelectedPalleteType[],
  selectedValue: SelectedPalleteType
) {
  return selectedPallete.some(
    (value) =>
      value.composition == selectedValue.composition &&
      value.pallet == selectedValue.pallet &&
      value.day == selectedValue.day &&
      value.supplier == selectedValue.supplier
  );
}

export function getQuantityCheckedPallets(
  selectedPallete: SelectedPalleteType[],
  selectedValue: Pick<SelectedPalleteType, "composition" | "day" | "supplier">
) {
  return selectedPallete.filter(
    (value) =>
      value.composition == selectedValue.composition &&
      value.day == selectedValue.day &&
      value.supplier == selectedValue.supplier
  ).length;
}

export function getPackagingSupplier(
  selectedPallete: SelectedPalleteType[],
  fkSupplier: string
) {
  return selectedPallete.filter((value) => value.supplier == fkSupplier);
}

export function getPackagingSupplierValues(
  selectedPallete: SelectedPalleteType[],
  selectedValue: Pick<
    SelectedPalleteType,
    "composition" | "day" | "supplier" | "pallet"
  >
) {
  return selectedPallete.find((value) => {
    return (
      value.composition == selectedValue.composition &&
      value.supplier == selectedValue.supplier &&
      value.day == selectedValue.day &&
      value.pallet == selectedValue.pallet
    );
  });
}

export function getComposition(
  packagings: PackagingType[],
  composition: string
) {
  if (!packagings || !composition) return;
  return packagings.find((packaging) => packaging.composicao == composition);
}

export function groupBySupplier(
  data: PackagingSupplierCellValueType[],
  packagingSupplier: PackagingSupplierGroupedType[]
): SuppliersPackageType[] {
  const packagingSupplierWithSupplierFk = data.map((item) => {
    const fkPackagingSupplier = String(item.FK_embalagem_fornecedor).includes(
      "LINHA_6"
    )
      ? split(String(item.FK_embalagem_fornecedor), "_")[0]
      : item.FK_embalagem_fornecedor;
    const supplierFk = packagingSupplier?.find((value) =>
      value.embalagem_fornecedores.find((e) => e == fkPackagingSupplier)
    );

    return {
      ...item,
      FK_fornecedor: supplierFk?.FK_fornecedor || "",
      nome_fornecedor: supplierFk?.nome_completo || "",
    };
  });

  const grouped = packagingSupplierWithSupplierFk.reduce((acc, item) => {
    let fornecedorGroup = acc.find((g) => g.fkSupplier == item.FK_fornecedor);
    if (!fornecedorGroup) {
      fornecedorGroup = {
        fkSupplier: item.FK_fornecedor,
        supplierName: item.nome_fornecedor,
        dates: [],
      };
      acc.push(fornecedorGroup);
    }
    let dateGroup = fornecedorGroup.dates.find((d) => d.day === item.dia);
    if (!dateGroup) {
      dateGroup = {
        day: item.dia,
        items: [],
      };
      fornecedorGroup.dates.push(dateGroup);
    }
    dateGroup.items.push(item);

    return acc;
  }, [] as SuppliersPackageType[]);
  return grouped;
}
