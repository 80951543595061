import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

type RealProductionType = {
  FK_produto: string;
  descricao: string;
  dia: number | string;
  codigo_fabrica: string;
  valor: string;
  codigo_linha_producao: string;
};

async function fetchRealProduction(data: {
  initialPeriod: string;
  finalPeriod: string;
  branchCode: string;
}) {
  if (!data) return;
  const response = await instance.post<ApiResponse<RealProductionType>>(
    "/planningbeer/realProduction",
    {
      ...data,
    }
  );
  return response.data;
}

export default function useRealProduction(data: {
  initialPeriod: string;
  finalPeriod: string;
  branchCode: string;
}) {
  return useQuery({
    queryKey: ["realProduction"],
    queryFn: () => fetchRealProduction(data),
  });
}
