import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { QUARENTINE_LINE, TEXT_COLOR } from "@/constants";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { SelectedPalletType } from "../Modal/LoadPlanner";
import Col from "@/components/Col";
import { Separator } from "@/components/ui/separator";
import { PackagingType } from "@/hooks/usePackagings";
import { getComposition } from "../../utils/buildLoadUtils";
import { quantityFormater } from "@/utils/number";
import Can from "@/components/Can";
import { Badge } from "@/components/ui/badge";

type SelectedPalletRowProps = SelectedPalletType & {
  packagings: PackagingType[];
  onRemove: () => void;
};

export default function SelectedPalletRow({
  composition,
  quantityPerPallet,
  packagings,
  pallet,
  onRemove,
}: SelectedPalletRowProps) {
  //prettier-ignore
  const packagingComposition = getComposition(packagings, composition)

  return (
    <React.Fragment>
      <Row align="center" className="gap-1">
        <Row className="gap-2 p-2" align="center">
          <Button size="icon" variant="outline" onClick={onRemove}>
            <FaArrowLeft size={16} color={TEXT_COLOR} />
          </Button>
        </Row>
        <Col className="gap-1 mb-1 mt-1">
          <Row className="font-bold">
            <Text>{packagingComposition?.composicao}</Text>
            <Text>
              {" - "}
              {packagingComposition?.descricao}
            </Text>
            <Can
              condition={pallet.includes(QUARENTINE_LINE)}
              onTrue={
                <Badge className="ml-4 mt-0" variant="outline">
                  Estratégico
                </Badge>
              }
            />
          </Row>
          <Text>
            Quantidade por Palete:{" "}
            {quantityFormater(Number(quantityPerPallet || 0))}
          </Text>
          <Text
            className="text-sm"
            style={{ fontSize: "10.5px", color: "white" }}
          >
            PLT {pallet}
          </Text>
        </Col>
      </Row>
      <Separator />
    </React.Fragment>
  );
}
