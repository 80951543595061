import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import useRealProduction from "@/hooks/useRealProduction";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { useEffect } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { generateScheduleProductionSheet } from "../../utils/scheduleProductionSheet";

export default function ExportRealProductionButton() {
  const {
    simulationData: {
      simulador_producao: { periodo_fim = "", periodo_inicio = "" },
      fabrica: { codigo },
    },
  } = useSimulationProductionContext();
  const { data, isFetching, refetch } = useRealProduction({
    initialPeriod: periodo_inicio,
    finalPeriod: periodo_fim,
    branchCode: codigo,
  });
  const { data: realProduction } = data || {};

  function formatRealProductionData() {
    if (!realProduction) return;

    const formatted = realProduction.map((line) => {
      return {
        FK_produto: line.FK_produto,
        descricao_produto: line.descricao,
        codigo_linha_de_producao: line.codigo_linha_producao,
        dia: String(line.dia),
        filial: line.codigo_fabrica,
        valor: line.valor,
        FK_fabrica_recurso: "",
      };
    });

    generateScheduleProductionSheet(formatted);
  }

  useEffect(() => {
    refetch();
  }, [periodo_fim, periodo_inicio, codigo]);

  if (isFetching) return <></>;
  return (
    <Button
      variant="ghost"
      className="w-full"
      onClick={formatRealProductionData}
    >
      <Row align="center" justify="start" className="w-full">
        <RiFileExcel2Fill size={18} color={TEXT_COLOR} />
        <Text className="mt-1 ml-1">Exportar Produção Real</Text>
      </Row>
    </Button>
  );
}
