import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type ProductionSimulationType = {
  SK_simulacao: number;
  FK_fabrica?: string | number;
  FK_plano_producao?: string | number;
  criado_por?: string;
  criado_por_nome?: string;
  descricao?: string;
  status?: string;
  periodo_inicio?: string;
  periodo_fim?: string;
  abreviatura_empresa?: string;
  codigo?: string;
  cnpj?: string;
  revisao?: string;
  descricao_plano_producao?: string;
  revisao_plano_producao?: string;
  observacao?: string;
  foi_incluido?: boolean;
};

export const productionSimulationsKey = {
  all: ["productionSimulations"] as const,
  show: (id: number) => [...productionSimulationsKey.all, "show", id] as const,
};

async function fetchProductionSimulation() {
  const response = await instance.post<ApiResponse<ProductionSimulationType>>(
    "/planningbeer/simulator"
  );

  return response.data;
}

export function useProductionSimulators() {
  return useQuery({
    queryKey: productionSimulationsKey.all,
    queryFn: fetchProductionSimulation,
    placeholderData: keepPreviousData,
  });
}
