import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";
import YearSelect from "@/components/Select/YearSelect";
import MonthSelect from "@/components/Select/MonthSelect";
import SemesterSelect from "@/components/Select/SemesterSelect";
import { fieldsToRender } from "../../utils/uploadSheetFormFields";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { schema } from "../../utils/form";
import { SHEET_HASH, STATE_OPTIONS } from "@/constants";
import { read, utils } from "xlsx";
import { useEffect, useRef, useState } from "react";
import useEstimateMutation from "@/hooks/useEstimateMutation";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Can from "@/components/Can";
import {
  getPeriodModel,
  validateDate,
  validateMonth,
  validateSemester,
  validateYear,
} from "./validate";
import { codeErrorSheet, handleSheetModel } from "../../utils/sheet";
import useBusiness from "@/hooks/useBusiness";
import { validationError } from "@/utils/api";
import { AxiosError } from "axios";
import { getPermissionCookie } from "@/utils/cookies";
dayjs.extend(customParseFormat);

const ACCEPTED_FILE_EXTENSIONS = "xlsx";
const TYPES = ["MENSAL", "SEMESTRAL", "ANUAL"];

export type UploadFileFormDataType = z.infer<typeof schema>;

type UploadFileFormProps = {
  handleModal: () => void;
};

type SheetColumnsType = {
  Vigencia: string;
  Filial_Origem: string;
  Destino: string;
  Código: string;
  Quantidade: string;
  Tipo_Plano: string;
};

export default function UploadFileForm({ handleModal }: UploadFileFormProps) {
  const permission = getPermissionCookie();
  const { data } = useBusiness();
  const { data: business } = data || {};
  const { mutateAsync, isPending } = useEstimateMutation();
  const [sheetData, setSheetData] = useState<SheetColumnsType[]>([]);
  const currentYear = new Date().getFullYear();
  const form = useForm<UploadFileFormDataType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const classification = form.watch("classification");
  const month = form.watch("month");
  const year = form.watch("year");
  const semester = form.watch("semester");
  const selectFileRef = useRef<HTMLInputElement>(null);

  function clearFileInput() {
    selectFileRef.current!.value = "";
    form.reset({ ...form.getValues(), sheet: undefined });
    setSheetData([]);
  }

  async function handleForm(data: UploadFileFormDataType) {
    if (isPending) return;
    const formData = {
      classification: data.classification,
      description: data.description,
      month: data.month,
      name: handleEstimateName(data.classification),
      semester: data.semester,
      year: data.year,
      sheet: sheetData,
      isLogistic: permission == "logistics" ? true : false,
    };

    try {
      await mutateAsync(formData);
      handleModal();
      toast.success("Estimativa de demanda enviada com sucesso!");
      form.reset();
      setSheetData([]);
      return;
    } catch (er: unknown | Error) {
      if (Object.prototype.hasOwnProperty.call(er, "response")) {
        const { response } = er as AxiosError;
        const { data } = response || {};
        const { message } = (data as any) || {};

        if (message) {
          const containsCodesLabel = message.includes("Códigos:");
          if (containsCodesLabel) {
            const match = message.match(/Códigos:\s*([\s\S]*)/);
            if (match) {
              const codesSection = match[1];
              const codes = codesSection.match(/\w+/g);
              if (codes) {
                codeErrorSheet(codes);
              }
            }
          }
        }
      }

      return validationError(er);
    }
  }

  function handleEstimateName(classification: string) {
    const { month, semester, year } = form.getValues();
    const name = {
      mensal: "planilha_" + classification + "_" + month + "_" + year,
      semestral: "planilha_" + classification + "_" + semester + "_" + year,
      anual: "planilha_" + classification + "_" + year,
    };

    return name[classification] || "";
  }

  function validateSheetRows(sheetRows: SheetColumnsType[]) {
    const { month, classification, year, semester } = form.getValues();
    sheetRows.map((row, index) => {
      const RowIndex = 2;
      if (!row) throw new Error("Inválido");
      const {
        Filial_Origem,
        Destino,
        Código: codigo,
        Quantidade,
        Vigencia,
        Tipo_Plano,
      } = row;

      const branches = business?.map(({ SK_EMPRESA }) => {
        return Number(SK_EMPRESA);
      });

      if (!branches?.includes(Number(Filial_Origem))) {
        throw new Error(
          `Filial de origem inválida na linha ${index + RowIndex}. Utilize uma das filiais cadastradas.`
        );
      }
      if (
        !STATE_OPTIONS.map((state) => state.key).includes(
          Destino?.toUpperCase()
        )
      ) {
        throw new Error(
          `Estado de destino inválido na linha ${index + RowIndex}, utilize a sigla do estado.`
        );
      }
      if (!codigo) {
        throw new Error(
          `Código inválido na linha ${index + RowIndex}, preencha o campo.`
        );
      }
      if (Number.isNaN(Number(Quantidade))) {
        throw new Error(
          `Quantidade inválida na linha ${index + RowIndex}, preencha o campo.`
        );
      }
      if (!TYPES.includes(String(Tipo_Plano?.toUpperCase()))) {
        throw new Error(
          `Tipo de plano inválido na linha ${index + RowIndex}, utilize MENSAL, SEMESTRAL ou ANUAL.`
        );
      }
      const newDate = validateDate(Vigencia, index + RowIndex);
      validateYear(
        year ?? "",
        dayjs(newDate, "DD/MM/YYYY").format("YYYY"),
        index + RowIndex
      );

      if (String(row.Tipo_Plano.toLowerCase()) != classification.toLowerCase())
        throw new Error(
          `Tipo de plano inválido na linha ${index + RowIndex}, utilize ${classification.toUpperCase()}.`
        );

      if (classification == "mensal") {
        return validateMonth(
          month ?? "",
          dayjs(newDate, "DD/MM/YYYY").format("MMMM"),
          index + RowIndex
        );
      }

      if (classification == "semestral") {
        return validateSemester(
          semester ?? "",
          dayjs(newDate, "DD/MM/YYYY").format("MMMM"),
          index + RowIndex
        );
      }
    });
  }

  async function handleExcelSheet(file: File) {
    const fileReader = await file.arrayBuffer();
    const workbook = read(fileReader, {
      cellDates: true,
    });

    const hiddenSheetId = workbook.SheetNames.indexOf(SHEET_HASH);
    if (hiddenSheetId == -1) throw new Error("Planilha inválida");

    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const sheetJson = utils.sheet_to_json<any>(sheet);

    const keys = Object.keys(sheetJson[0] || {});
    const acceptedKeys = [
      "Vigencia",
      "Filial_Origem",
      "Destino",
      "Código",
      "Quantidade",
      "Tipo_Plano",
    ];

    if (!keys || JSON.stringify(keys) != JSON.stringify(acceptedKeys))
      throw new Error("Planilha inválida");

    validateSheetRows(sheetJson);

    const formatedSheet = sheetJson.map((row) => {
      if (typeof row?.Vigencia === "object") {
        row.Vigencia = dayjs(row.Vigencia).format("DD/MM/YYYY");
      }

      return row;
    });

    return formatedSheet;
  }

  useEffect(() => {
    clearFileInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification, month, semester, year]);

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleForm)}
      >
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Descrição *</FormLabel>
              <FormControl>
                <Textarea
                  maxLength={250}
                  rows={6}
                  style={{ maxHeight: 300 }}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Separator />
        <FormField
          control={form.control}
          name="classification"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Classificação *</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex"
                >
                  <FormItem className="flex items-center space-x-1 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="mensal" />
                    </FormControl>
                    <FormLabel className="font-normal">Mensal</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-1 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="semestral" />
                    </FormControl>
                    <FormLabel className="font-normal">Semestral</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-1 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="anual" />
                    </FormControl>
                    <FormLabel className="font-normal">Anual</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <Can
            condition={fieldsToRender("month", classification)}
            onTrue={
              <FormField
                control={form.control}
                name="month"
                defaultValue="janeiro"
                render={({ field }) => (
                  <FormItem className="grow w-1/2">
                    <FormLabel>Mês *</FormLabel>
                    <FormControl>
                      <MonthSelect
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            }
          />
          <Can
            condition={fieldsToRender("semester", classification)}
            onTrue={
              <FormField
                control={form.control}
                name="semester"
                defaultValue="primeiro_semestre"
                render={({ field }) => (
                  <FormItem className="grow w-1/2">
                    <FormLabel>Semestre *</FormLabel>
                    <FormControl>
                      <SemesterSelect
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            }
          />
          <Can
            condition={fieldsToRender("year", classification)}
            onTrue={
              <FormField
                control={form.control}
                name="year"
                defaultValue={currentYear.toString()}
                render={({ field }) => (
                  <FormItem className="grow w-1/2">
                    <FormLabel>Ano *</FormLabel>
                    <FormControl>
                      <YearSelect
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            }
          />
        </Row>
        <Separator />
        <FormField
          control={form.control}
          name="sheet"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Planilha *</FormLabel>
                <FormControl>
                  <Row align="center">
                    <Input
                      ref={selectFileRef}
                      type="file"
                      accept=".xlsx"
                      onChange={async (e) => {
                        const file = e.target.files![0];
                        const fileExtension = file.name?.split(".").pop();
                        if (fileExtension == ACCEPTED_FILE_EXTENSIONS) {
                          try {
                            const sheetValues = await handleExcelSheet(file);
                            setSheetData(sheetValues);
                          } catch (error) {
                            toast.error(error as unknown as string);
                            e.target.value = "";
                          }
                          return field.onChange(e.target.files![0]);
                        }
                        toast.error(
                          "O arquivo deve ser um arquivo Excel (.xlsx)"
                        );
                        e.target.value = "";
                        field.onChange(null);
                        return;
                      }}
                    />
                  </Row>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <Row align="center" justify="end" className="gap-2">
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              const period = getPeriodModel({ month, semester, year });
              handleSheetModel(period, 0, classification?.toUpperCase());
            }}
          >
            <Text color="primary">Baixar modelo</Text>
          </Button>
          <Button type="submit" disabled={isPending}>
            <Text color="white">Salvar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
