import Col from "@/components/Col";
import Each from "@/components/Each";
import Text from "@/components/Text/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import { calculateTotalPallets } from "@/pages/SimulacoesEmbalagem/utils/carga";
import LoadPackagingSupplierHeader from "../Header/LoadPackagingSupplierHeader";
import {
  SelectedPalleteType,
  SuppliersPackageType,
} from "../Steps/BuildLoad/LoadPackaging";
import { PackagingType } from "@/hooks/usePackagings";
import PalletRow from "../Row/PalletRow";
import React from "react";
import { generatePalletId } from "../Modal/LoadPlanner";
import { QUARENTINE_LINE } from "@/constants";
import Can from "@/components/Can";
import SelectMultiplePalletDropdown from "../Dropdown/SelectMultiplePalletDropdown";

export type LoadPackagingContentProps = {
  dates: SuppliersPackageType["dates"];
  palletsPerCargo: number;
  packagings: PackagingType[];
  supplierFk: string;
  supplierName: string;
  selectedPallets: string[];
  selectedPalletsQuantity: number;
  onCheckPallete: (selectedValue: SelectedPalleteType) => void;
};

export type HandleSelectMultipleProps = {
  composition: string;
  day: string;
  fkSupplier: string;
  quantityPerPallet: number;
  quantity: number;
  pallets: string[];
};

// composition: composition,
// pallet: palletId,
// day: day,
// supplier: fkSupplier,
// quantityPerPallet: Number(quantityPerPallet || 0),
// type: "default",

export default function LoadPackagingContent({
  dates,
  selectedPallets,
  packagings,
  palletsPerCargo,
  supplierFk,
  supplierName,
  selectedPalletsQuantity,
  onCheckPallete,
}: LoadPackagingContentProps) {
  function handleSelectMultiple({
    quantity,
    composition,
    day,
    fkSupplier,
    pallets,
    quantityPerPallet,
  }: HandleSelectMultipleProps) {
    const palletsId = pallets
      .filter((pallet) => !selectedPallets.includes(pallet))
      .splice(0, quantity);

    for (let i = 0; i < quantity; i++) {
      onCheckPallete({
        composition,
        day,
        quantityPerPallet,
        type: "default",
        pallet: palletsId[i],
        supplier: fkSupplier,
      });
    }
  }

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={supplierFk.toString()}>
        <AccordionTrigger className="p-2 mb-2 mt-2">
          <Text className="text-sm">
            Fornecedor: <strong>{supplierName}</strong>
          </Text>
        </AccordionTrigger>
        <Separator />
        <AccordionContent className="gap-2">
          <Each
            array={dates}
            renderItem={({ day, items }) => {
              return (
                <>
                  <Col className="ml-4 p-2 gap-1">
                    <Text className="font-bold text-sm">
                      Dia: {dayjs(day).format("DD/MM/YYYY")}
                    </Text>
                    <Each
                      array={items}
                      renderItem={({
                        composicao: composition,
                        valor: value,
                        quantidade_por_palete: quantityPerPallet,
                        FK_embalagem_fornecedor: fkPackagingSupplier,
                      }) => {
                        const packagingSupplierFk = String(fkPackagingSupplier);
                        const totalPallets = calculateTotalPallets(
                          Number(value || 0),
                          Number(quantityPerPallet || 0)
                        );

                        const arrayPallets = Array.from(
                          { length: totalPallets },
                          (_, index) => {
                            return generatePalletId({
                              composition: String(composition),
                              day: dayjs(day).format("YYYYMMDD"),
                              fkPackagingSupplier: packagingSupplierFk,
                              index: index + 1,
                            });
                          }
                        );

                        const selectedQuantity = arrayPallets.filter(
                          (pallet) => {
                            return selectedPallets.includes(pallet);
                          }
                        ).length;

                        if (totalPallets == 0) return null;
                        return (
                          <Col className="border rounded-sm p-2">
                            <Accordion type="single" collapsible>
                              <AccordionItem value={items.toString()}>
                                <AccordionTrigger className="p-2">
                                  <LoadPackagingSupplierHeader
                                    quantitySelected={selectedQuantity}
                                    totalPallets={arrayPallets.length}
                                    isLineSix={packagingSupplierFk.includes(
                                      QUARENTINE_LINE
                                    )}
                                    packagings={packagings}
                                    composition={composition}
                                    quantityPerPallet={Number(
                                      quantityPerPallet
                                    )}
                                  />
                                </AccordionTrigger>
                                <AccordionContent className="h-[28rem] overflow-y-scroll">
                                  <Each
                                    array={arrayPallets}
                                    renderItem={(pallet, index) => {
                                      return (
                                        <React.Fragment>
                                          <Can
                                            condition={index == 0}
                                            onTrue={
                                              <SelectMultiplePalletDropdown
                                                maxPallets={
                                                  palletsPerCargo -
                                                  selectedPalletsQuantity
                                                }
                                                onAddPallets={(quantity) => {
                                                  handleSelectMultiple({
                                                    quantity,
                                                    composition: composition,
                                                    day: day,
                                                    fkSupplier: supplierFk,
                                                    pallets: arrayPallets,
                                                    quantityPerPallet:
                                                      Number(quantityPerPallet),
                                                  });
                                                }}
                                              />
                                            }
                                          />
                                          <PalletRow
                                            composition={composition}
                                            day={day}
                                            fkSupplier={supplierFk}
                                            palletId={pallet}
                                            index={index}
                                            quantityPerPallet={
                                              quantityPerPallet
                                            }
                                            selectedPallets={selectedPallets}
                                            onCheckPallete={onCheckPallete}
                                          />
                                        </React.Fragment>
                                      );
                                    }}
                                  />
                                </AccordionContent>
                              </AccordionItem>
                            </Accordion>
                          </Col>
                        );
                      }}
                    />
                  </Col>
                  <Separator />
                </>
              );
            }}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
