import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";
import { PackagingSupplierCellValueType } from "./usePackagingSimulationMutation";

type PackagingSimulatorType = {
  FK_simulacao: number;
  valores_fornecedores: Array<PackagingSupplierCellValueType>;
  FK_plano_embalagem: number;
  observacao: string;
  revisao: string;
};

export default function usePackagingSimulatorMutation() {
  return useMutation({
    mutationFn: async (data: PackagingSimulatorType) => {
      return instance.put("/planningbeer/packagingSimulator", { ...data });
    },
  });
}
