import Row from "@/components/Row";
import IndisponibilityLineToolTip from "../Popover";
import EditLineCellPopover from "../Popover/EditLineCellPopover";
import { LineCellProps } from "./LineCell";
import { useSimulationProductionContext } from "../../context";
import Can from "@/components/Can";
import {
  DemandEstimateResponseType,
  ProductLineType,
} from "@/hooks/useProductionSimulationMutation";
import dayjs from "dayjs";
import { EMPTY_VALUE } from "@/constants";
import { calculateIndisponibilityProduction } from "../../utils/inventory/line";

type IndisponibilityLineCellProps = {
  cellValue: string;
  indisponibilityLine: {
    dates: string[];
    motivo: string;
    FK_fabrica_recurso: string | number;
  }[];
  product: DemandEstimateResponseType;
  line?: ProductLineType;
} & LineCellProps;

function calculateIndisponibilityHours(
  indisponibilities: IndisponibilityLineCellProps["indisponibilityLine"]
) {
  if (indisponibilities.length == EMPTY_VALUE) return;

  return indisponibilities.reduce((acc, { dates }) => {
    const start = dayjs(dates[0]);
    const end = dayjs(dates[1]);

    const duration = end.diff(start, "hour", true);

    return acc + duration;
  }, 0);
}

export default function IndisponibilityLineCell({
  cellValue,
  indisponibilityLine,
  onChange,
  line,
  product: {
    extra: { fator_conversao },
  },
  disabled: disabledCell = false,
}: IndisponibilityLineCellProps) {
  const {
    icons: { disabled },
  } = useSimulationProductionContext();
  const totalIndisponibilityHours =
    calculateIndisponibilityHours(indisponibilityLine);

  const maxProduction = calculateIndisponibilityProduction(
    Number(line?.capacidade || 0),
    Number(fator_conversao),
    24,
    totalIndisponibilityHours
  );

  function onValueChange(value?: string) {
    onChange?.(value);
  }

  return (
    <Row justify="space-between" className="w-full" align="center">
      {cellValue}
      <Can
        condition={!disabled && !disabledCell}
        onTrue={
          <Row align="center" className="gap-1" justify="center">
            <EditLineCellPopover
              value={cellValue}
              onChange={onValueChange}
              maxProduction={maxProduction}
            />
            <IndisponibilityLineToolTip
              indisponibilityLine={indisponibilityLine}
            />
          </Row>
        }
      />
    </Row>
  );
}
