import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { DecreaseInventoryType } from "../hooks/useDecreaseInventories";

type DialogTypeType = "edit" | "delete" | "nonSelected";

type DecreaseInventoryContextProps = {
  handleDialog: () => void;
  handleDialogType: (type: DialogTypeType) => void;
  handleDecreaseInventory: (decreaseInventory: DecreaseInventoryType) => void;
  clear: () => void;
  visibleDialog: boolean;
  dialogType: DialogTypeType;
  decreaseInventory: DecreaseInventoryType;
};

const DecreaseInventoryContext = createContext(
  {} as DecreaseInventoryContextProps
);

export default function DecreaseInventoryProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [decreaseInventory, setDecreaseInventory] =
    useState<DecreaseInventoryType>({} as DecreaseInventoryType);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleDecreaseInventory(value: DecreaseInventoryType) {
    setDecreaseInventory(value);
  }

  function clear() {
    setVisibleDialog(false);
    setDecreaseInventory({} as DecreaseInventoryType);
    setDialogType("nonSelected");
  }

  return (
    <DecreaseInventoryContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleDecreaseInventory,
        decreaseInventory,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </DecreaseInventoryContext.Provider>
  );
}

export function useDecreaseInventoryContext() {
  return useContext(DecreaseInventoryContext);
}
