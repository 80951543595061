import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";
import { DiffType } from "@/utils/diff";
import { quantityFormater } from "@/utils/number";
import dayjs from "dayjs";
import { FaArrowRight } from "react-icons/fa";

type DiffViewerProps = {
  diffs: Array<DiffType>;
};

const TYPE = {
  added: "Adicionado",
  removed: "Removido",
  modified: "Modificado",
};

const BADGE_COLOR = {
  added: "bg-lime-300",
  removed: "bg-red-300",
  modified: "bg-yellow-300",
};

export default function DiffViewer({ diffs }: DiffViewerProps) {
  return (
    <div className="space-y-4 mt-4">
      {diffs.map((diff, index) => {
        return (
          <div key={index} className={`p-4 border rounded-lg`}>
            <Row align="center">
              <Text
                className={`capitalize ${BADGE_COLOR[diff.type]} bg-lime-300 rounded-full px-2 py-1 text-sm`}
              >
                {TYPE[diff.type]}
              </Text>
            </Row>
            <div className="mt-2">
              {diff.type == "modified" ? (
                Object.keys(diff.changes || {}).map((field, idx) => {
                  const from = diff?.changes?.[field].from;
                  const to = diff?.changes?.[field].to;

                  return (
                    <Col key={idx} className="ml-4 text-sm gap-2">
                      <Row justify="space-between">
                        <Row align="center">
                          <Text>Valor: </Text>
                          <Row align="center" className="ml-1 gap-1">
                            <span
                              className="font-bold"
                              style={{ color: TEXT_COLOR }}
                            >
                              De {quantityFormater(from)}{" "}
                            </span>
                            <FaArrowRight size={15} color={TEXT_COLOR} />{" "}
                            <span
                              className="font-bold"
                              style={{ color: TEXT_COLOR }}
                            >
                              {quantityFormater(to)}
                            </span>
                          </Row>
                        </Row>
                        <Text>
                          Dia:{" "}
                          <span className="font-bold">
                            {dayjs(diff?.item.dia).format("DD/MM/YYYY")}
                          </span>
                        </Text>
                      </Row>
                      <Row justify="space-between">
                        <Text>
                          Produto:{" "}
                          <span className="font-bold">
                            {diff?.item.composicao}
                          </span>
                        </Text>
                      </Row>
                    </Col>
                  );
                })
              ) : (
                <Col className="ml-4 text-sm gap-2">
                  <Row justify="space-between">
                    <Row align="center">
                      <Text>Valor: </Text>
                      <Row align="center" className="ml-1 gap-1">
                        <span
                          className="font-bold"
                          style={{ color: TEXT_COLOR }}
                        >
                          {quantityFormater(diff.item.valor)}{" "}
                        </span>
                      </Row>
                    </Row>
                    <Text>
                      Dia:{" "}
                      <span className="font-bold">
                        {dayjs(diff?.item.dia).format("DD/MM/YYYY")}
                      </span>
                    </Text>
                  </Row>
                  <Row justify="space-between">
                    <Text>
                      Produto:{" "}
                      <span className="font-bold">{diff?.item.composicao}</span>
                    </Text>
                  </Row>
                </Col>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
