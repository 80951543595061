import { ProductType } from "@/hooks/useProducts";
import { utils, writeFile } from "xlsx";

export function generateProductsSheet(
  activeProducts: ProductType[],
  inactiveProducts: ProductType[]
) {
  const products = activeProducts.concat(inactiveProducts);
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([[]]);

  const formatedData = products.map((product) => {
    return {
      Código: product.SK_produto,
      Descrição: product.descricao,
      Ativo: product.ativo ? "Sim" : "Não",
    };
  });

  ws["A1"] = { t: "s", v: "Código" };
  ws["B1"] = { t: "s", v: "Descrição" };
  ws["C1"] = { t: "s", v: "Ativo" };

  utils.sheet_add_json(ws, formatedData, {
    origin: {
      r: 1,
      c: 0,
    },
    skipHeader: true,
  });
  utils.book_append_sheet(wb, ws, "Produtos");
  writeFile(wb, "Produtos.xlsx");
}
