import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import Col from "../Col";

type TransferProps<L, R> = {
  leftData: Array<L>;
  rightData: Array<R>;
  titleLeft?: React.ReactNode;
  titleRight?: React.ReactNode;
  renderLeftElement: (data: Array<L>) => React.ReactNode;
  renderRightElement: (data: Array<R>) => React.ReactNode;
  onHandleLeftToRight?: () => void;
  onHandleRightToLeft?: () => void;
  onHandleAllLeftToRight?: () => void;
  onHandleAllRightToLeft?: () => void;
};

export default function Transfer<L, R>({
  renderLeftElement,
  renderRightElement,
  onHandleLeftToRight,
  onHandleRightToLeft,
  onHandleAllLeftToRight,
  onHandleAllRightToLeft,
  titleLeft,
  titleRight,
  leftData,
  rightData,
}: TransferProps<L, R>) {
  return (
    <div className="flex w-full h-full rounded-sm">
      <div className="w-1/2 border-r overflow-y-scroll">
        {titleLeft}
        {renderLeftElement(leftData)}
      </div>
      <Col className="mx-6 items-center justify-center cursor-pointer">
        <MdKeyboardDoubleArrowLeft
          className="mb-2"
          size={22}
          onClick={onHandleAllRightToLeft}
        />
        <MdKeyboardArrowLeft size={30} onClick={onHandleRightToLeft} />
        <MdKeyboardArrowRight size={30} onClick={onHandleLeftToRight} />
        <MdKeyboardDoubleArrowRight
          className="mt-2"
          size={22}
          onClick={onHandleAllLeftToRight}
        />
      </Col>
      <div className="w-1/2 border-l overflow-y-scroll">
        {titleRight}
        {renderRightElement(rightData)}
      </div>
    </div>
  );
}
