import i18n from "@/i18n";
import axios, { AxiosError } from "axios";
import { toast } from "sonner";

type ApiError = {
  status: number;
  erro: string;
};

type ValidationError = {
  message: {
    message: string;
    path: string[];
    type: string;
    context: {
      valids: string[];
      label: string;
      value: string;
      key: string;
    };
  }[];
};

const CLIENT_VALIDATION_EXCEPTION = 400;
const CLIENT_NON_AUTHORIZED = 401;

export function transformApiError(e: AxiosError | Error | unknown) {
  if (Object.prototype.hasOwnProperty.call(e, "response")) {
    const { response } = e as AxiosError;
    const error = response?.data as ApiError;

    if (error.status < 500) return toast.error(error.erro);
    return toast.error(i18n.defaultApiError);
  }
}

export function validationError(e: AxiosError | Error | unknown) {
  if (axios.isAxiosError(e)) {
    if (Object.prototype.hasOwnProperty.call(e, "response")) {
      const { response } = e;
      const { data, status } = response || {};

      if (status == CLIENT_NON_AUTHORIZED) {
        return toast.error(i18n.unauthorized);
      }

      if (data.message && status == CLIENT_VALIDATION_EXCEPTION) {
        const { message } = data as ValidationError;
        if (typeof message === "string") return toast.error(message);
        if (Array.isArray(message)) {
          for (const error of message) {
            toast.error(error.message);
          }
        }
        return toast.error(i18n.defaultApiError);
      }
      if (data.message) {
        if (typeof data.message === "string") return toast.error(data.message);
        return toast.error(i18n.defaultApiError);
      }
      return toast.error("Ocorreu um erro desconhecido!");
    }
    return toast.error(i18n.defaultApiError);
  }
  return toast.error(i18n.defaultApiError);
}
