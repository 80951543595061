import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { usePackagingPlanContext } from "../../context";
import { handleSheetModel } from "../../utils/sheet";
import usePackagings, { PackagingProductType } from "@/hooks/usePackagings";
import { useEffect, useState } from "react";
import useFactories from "@/hooks/useFactories";
import Empty from "@/components/Empty";
import { FactoryColumnsType } from "@/pages/Factory";
import {
  PackagingType,
  usePackagingSuppliers,
} from "@/hooks/usePackagingSuppliers";
import Row from "@/components/Row";
import { RiFileExcel2Fill } from "react-icons/ri";
import { TEXT_COLOR } from "@/constants";
import useCargoModels, {
  CargoModelType,
} from "@/pages/ModeloCarga/hooks/useCargoModels";

export type ExtraInfoType = {
  factories: Record<string, FactoryColumnsType>;
  packagings: Record<
    string,
    {
      produtos: PackagingProductType[];
      composicao: string;
      descricao: string;
    }
  >;
  packagingSuppliers: Record<string, PackagingType>;
  cargoModels: Record<string, CargoModelType>;
};

export default function DownloadExcel() {
  const {
    packagingPlan: { valores_fornecedores },
  } = usePackagingPlanContext();
  const { data: packagingsData, isPending: isPendingPackagins } =
    usePackagings();
  const { data: packagingSuppliersData, isPending: isPendingSuppliers } =
    usePackagingSuppliers();
  const { data: factoriesData, isPending: isPendingFactories } = useFactories();
  const { data: cargoModelsData, isPending: isPendingCargoModels } =
    useCargoModels();

  const { data: packagings = [] } = packagingsData || {};
  const { data: packagingSuppliers = [] } = packagingSuppliersData || {};
  const { data: factories = [] } = factoriesData || {};
  const { data: cargoModels = [] } = cargoModelsData || {};

  const [extraInfo, setExtraInfo] = useState<ExtraInfoType>({
    packagingSuppliers: {},
    factories: {},
    packagings: {},
    cargoModels: {},
  });

  useEffect(() => {
    const worker = new Worker(
      new URL("../../worker/packagingsWorker.ts", import.meta.url),
      { type: "module" }
    );
    worker.postMessage({
      packagings,
    });
    worker.onmessage = (event) => {
      setExtraInfo((prev) => ({
        ...prev,
        packagings: event.data,
      }));
      worker.terminate();
    };
    return () => {
      worker.terminate();
    };
  }, [packagings]);

  useEffect(() => {
    const worker = new Worker(
      new URL("../../worker/suppliersWorker.ts", import.meta.url),
      { type: "module" }
    );
    worker.postMessage({
      packagingSuppliers,
    });
    worker.onmessage = (event) => {
      setExtraInfo((prev) => ({
        ...prev,
        packagingSuppliers: event.data,
      }));
      worker.terminate();
    };
    return () => {
      worker.terminate();
    };
  }, [packagingSuppliers]);

  useEffect(() => {
    const worker = new Worker(
      new URL("../../worker/factoriesWorker.ts", import.meta.url),
      { type: "module" }
    );
    worker.postMessage({
      factories,
    });
    worker.onmessage = (event) => {
      setExtraInfo((prev) => ({
        ...prev,
        factories: event.data,
      }));
      worker.terminate();
    };
    return () => {
      worker.terminate();
    };
  }, [factories]);

  useEffect(() => {
    const worker = new Worker(
      new URL("../../worker/cargoModelWorker.ts", import.meta.url),
      { type: "module" }
    );
    worker.postMessage({
      cargoModels,
    });
    worker.onmessage = (event) => {
      setExtraInfo((prev) => ({
        ...prev,
        cargoModels: event.data,
      }));
      worker.terminate();
    };
    return () => {
      worker.terminate();
    };
  }, [cargoModels]);

  if (
    isPendingPackagins ||
    isPendingSuppliers ||
    isPendingFactories ||
    isPendingCargoModels
  )
    return <Empty />;

  return (
    <Button
      variant="link"
      onClick={() =>
        handleSheetModel(JSON.parse(valores_fornecedores || "[]"), extraInfo)
      }
    >
      <Row align="center">
        <RiFileExcel2Fill size={20} color={TEXT_COLOR} />
        <Text className="ml-2 mt-1">Gerar Excel dos Valores Preenchidos</Text>
      </Row>
    </Button>
  );
}
