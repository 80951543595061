import {
  transporterKeys,
  TransporterType,
} from "@/pages/Transportador/hooks/useTransporters";
import Each from "../Each";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useQueryClient } from "@tanstack/react-query";
import { SelectProps } from "@radix-ui/react-select";

type TransporterData = {
  data: Array<TransporterType>;
};

type TransporterSelectProps = {
  keyValue?: (data: TransporterType) => string;
} & SelectProps;

export default function TransporterSelect(props: TransporterSelectProps) {
  const queryClient = useQueryClient();

  const transporterData: TransporterData | undefined = queryClient.getQueryData(
    transporterKeys.active
  );
  const { data: transporter } = transporterData || {};
  if (!transporter) return null;

  return (
    <Select
      value={props.value}
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
    >
      <SelectTrigger>
        <SelectValue placeholder="Transportador" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={transporter || []}
          renderItem={(propsItem) => {
            return (
              <SelectItem
                value={
                  props.keyValue
                    ? props.keyValue(propsItem)
                    : String(propsItem.codigo)
                }
              >
                {propsItem.nome_fantasia} {" - "}
                {propsItem.cidade}
                {", "}
                {propsItem.uf}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
