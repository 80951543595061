import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import SupplierSelect from "@/components/Select/SupplierSelect";
import { PackagingSupplierFormType } from "./zod";
import ProductPackagingSelect from "@/components/Select/ProductPackagingSelect";
import FactorySelect from "@/components/Select/FactorySelect";
import { Input } from "@/components/ui/input";
import BusinessSelect from "@/components/Select/BusinessSelect";

type PackagingSupplierFormProps = {
  form: UseFormReturn<PackagingSupplierFormType, any, undefined>;
  button: {
    title: string;
    disabled: boolean;
  };
  onSubmit: (data: PackagingSupplierFormType) => void;
};

export default function PackagingSupplierForm({
  onSubmit,
  form,
  button,
}: PackagingSupplierFormProps) {
  function handlePackagingSelect(value) {
    const previousValue = form.getValues("fkPackaging") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("fkPackaging", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("fkPackaging", values);
  }

  function handleSupplierSelect(value) {
    const previousValue = form.getValues("fkSupplier") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("fkSupplier", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("fkSupplier", values);
  }

  function handleFactorySelect(value) {
    const previousValue = form.getValues("fkFactory") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("fkFactory", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("fkFactory", values);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="fkSupplier"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Fornecedor *</Text>
              </FormLabel>
              <FormControl>
                <SupplierSelect
                  isMultiple
                  handleSelected={handleSupplierSelect}
                  selected={field.value || []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fkPackaging"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Embalagem *</Text>
              </FormLabel>
              <FormControl>
                <ProductPackagingSelect
                  isMultiple
                  handleSelected={handlePackagingSelect}
                  selected={field.value || []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fkFactory"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Fábrica *</Text>
              </FormLabel>
              <FormControl>
                <FactorySelect
                  isMultiple
                  handleSelected={handleFactorySelect}
                  selected={field.value || []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="leadTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Lead Time (em dias) *</Text>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="palleteQuantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Quantidade por Palete *</Text>
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="operationType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Tipo de Operação *</Text>
              </FormLabel>
              <FormControl>
                <BusinessSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}
