import { DatePicker } from "@/components/DatePicker";
import ProductPackagingSelect from "@/components/Select/ProductPackagingSelect";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { REQUIRED_FIELD, TEXT_COLOR } from "@/constants";
import { DialogProps } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { MdFilterAlt } from "react-icons/md";
import { z } from "zod";

const schema = z.object({
  initialPeriod: z.date({ required_error: REQUIRED_FIELD }),
  finalPeriod: z.date({ required_error: REQUIRED_FIELD }),
  packagings: z.array(z.string()).optional(),
});

export type FormSchema = z.infer<typeof schema>;

type FilterLoadPlannerPackageDropdownProps = {
  onFilter: (data: FormSchema) => void;
  onClear: () => void;
} & DialogProps;

export default function FilterLoadPlannerPackageDropdown({
  visible = false,
  handleClose,
  onFilter,
  onClear,
}: FilterLoadPlannerPackageDropdownProps) {
  const form = useForm<FormSchema>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function handlePackagingSelect(value) {
    const previousValue = form.getValues("packagings") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("packagings", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("packagings", values);
  }

  return (
    <DropdownMenu onOpenChange={handleClose} open={visible}>
      <DropdownMenuTrigger>
        <Button size="icon" variant="outline" className="mr-2">
          <MdFilterAlt size={24} color={TEXT_COLOR} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <Form {...form}>
          <form
            className="flex flex-col gap-2 p-4"
            onSubmit={form.handleSubmit(onFilter)}
          >
            <FormField
              control={form.control}
              name="initialPeriod"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Período Inicial *</FormLabel>
                  <FormControl>
                    <DatePicker value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="finalPeriod"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Período Final *</FormLabel>
                  <FormControl>
                    <DatePicker value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="packagings"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Embalagens</FormLabel>
                  <FormControl>
                    <ProductPackagingSelect
                      isMultiple
                      handleSelected={handlePackagingSelect}
                      selected={field.value || []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">
              <Text color="white">Filtrar</Text>
            </Button>
            <Button
              type="button"
              onClick={() => {
                form.reset({
                  finalPeriod: undefined,
                  initialPeriod: undefined,
                  packagings: [],
                });
                onClear();
                handleClose();
              }}
              variant="outline"
            >
              <Text>Limpar</Text>
            </Button>
          </form>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
