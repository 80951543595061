import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { PackagingType } from "@/hooks/usePackagings";
import { useMemo } from "react";
import { getComposition } from "../../utils/buildLoadUtils";
import { Badge } from "@/components/ui/badge";
import Can from "@/components/Can";
import Col from "@/components/Col";
import { quantityFormater } from "@/utils/number";

type LoadPackagingSupplierHeaderProps = {
  composition: string;
  isLineSix: boolean;
  totalPallets?: number;
  quantitySelected?: number;
  packagings?: PackagingType[];
  quantityPerPallet?: number;
};

export default function LoadPackagingSupplierHeader({
  isLineSix,
  composition,
  totalPallets = 0,
  quantityPerPallet = 0,
  quantitySelected = 0,
  packagings = [],
}: LoadPackagingSupplierHeaderProps) {
  const packagingComposition = useMemo(
    () => getComposition(packagings, composition),
    []
  );

  const total = totalPallets - quantitySelected;
  const quantity = total * quantityPerPallet;

  return (
    <Col className="items-start">
      <Row className="gap-2">
        <Text>
          {packagingComposition?.composicao.toString() +
            " - " +
            packagingComposition?.descricao}
        </Text>
        <Can
          condition={isLineSix}
          onTrue={
            <Badge variant="outline">{isLineSix ? "Estratégico" : ""}</Badge>
          }
        />
      </Row>
      <Text>
        Total de Paletes:{" "}
        <strong>
          {totalPallets - quantitySelected}/{totalPallets}
        </strong>
        {" - "}Quantidade: <strong>{quantityFormater(quantity)}</strong>
      </Text>
    </Col>
  );
}
