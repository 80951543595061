import { CellLineValuesType } from "@/hooks/useProductionSimulationMutation";
import { createInventoryForDate } from ".";
import { getCellDayLineValue } from "../cell";
import {
  CalculateLineProps,
  ExistsRealProductionProps,
  GetResourceFactoryProductProps,
  GetResourcesFactoriesProductProps,
} from "../../types";

function existsRealProduction({
  onFalse,
  productRealProduction,
}: ExistsRealProductionProps) {
  if (!productRealProduction) return onFalse();
  return Number(productRealProduction.valor);
}

export function calculateCellLineTotal(
  cellLineValue: Array<CellLineValuesType>
) {
  return cellLineValue.reduce(
    (acc, cell) => Number(acc) + Number(cell.valor),
    0
  );
}

export function calculateLine({
  decrease,
  cellLineValues,
  date,
  demand,
  product,
  isUnavailable,
  currentDay,
  unavailableInventory,
  currentInventory,
  productRealProduction: { isRealProduction, realProduction },
  canUseLineSixValue,
  usableLineSixValue,
  isDayBeforeCurrentDay,
  movement,
}: CalculateLineProps) {
  const cellLineValue = getCellDayLineValue({
    cellLineValues,
    date,
    product: product.produto,
    currentDay,
  });

  const cellLineTotal = isRealProduction
    ? existsRealProduction({
        onFalse: () => calculateCellLineTotal(cellLineValue),
        productRealProduction: realProduction,
      })
    : calculateCellLineTotal(cellLineValue);

  const calculatedInventoryLeft =
    cellLineTotal + currentInventory - demand - movement;
  const remainingInventory =
    canUseLineSixValue && isRealProduction
      ? calculatedInventoryLeft + usableLineSixValue
      : calculatedInventoryLeft;

  if (isUnavailable) {
    const inventoryForDate = createInventoryForDate({
      remainingInventory: unavailableInventory + cellLineTotal - decrease,
      currentInventory,
      date,
      isDayBeforeCurrentDay,
      movement,
    });
    currentInventory =
      unavailableInventory + cellLineTotal - decrease - movement;
    return { inventoryForDate, inventory: currentInventory };
  }

  const inventoryForDate = createInventoryForDate({
    remainingInventory: remainingInventory - decrease,
    currentInventory,
    date,
    isDayBeforeCurrentDay,
    movement,
  });

  currentInventory = remainingInventory - decrease - movement;
  return { inventoryForDate, inventory: currentInventory };
}

export function getResourcesFactoriesProducts({
  demandEstimate,
}: GetResourcesFactoriesProductProps) {
  return demandEstimate.flatMap((demand) => {
    return demand.produto_linhas;
  });
}

export function getResourceFactoryProduct({
  resourcesFactoriesProducts,
  skResourceFactoryProduct,
}: GetResourceFactoryProductProps) {
  if (!skResourceFactoryProduct) return;
  return resourcesFactoriesProducts.find(
    (resourceFactoryProduct) =>
      resourceFactoryProduct.SK_recurso_fabrica_produto ==
      skResourceFactoryProduct
  );
}

export function calculateIndisponibilityProduction(
  capacidadeProducaoUnidades: number,
  fatorConversao: number,
  tempoTotalHoras = 24,
  horasIndisponiveis?: number
) {
  if (!horasIndisponiveis) return;
  const producaoTotalUnidades =
    capacidadeProducaoUnidades * (tempoTotalHoras - horasIndisponiveis);

  const producaoTotalCaixas = producaoTotalUnidades / fatorConversao;

  return Math.floor(producaoTotalCaixas);
}
