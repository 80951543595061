import { quantityFormater } from "@/utils/number";
import { utils, writeFile } from "xlsx";

export type ProductDisponibilityFormatType = {
  produto: string;
  descricao: string;
  producao_real: string | number;
  venda_grade: string | number;
  inventario: {
    atual: number;
    restante: number;
  };
}[];

export function generateDisponibilitySheet(
  data: ProductDisponibilityFormatType,
  day: string,
  factoryDate: string
) {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([[]]);

  ws["A1"] = { t: "s", v: `Dia - ${day}` };

  ws["A3"] = { t: "s", v: "Filial" };
  ws["B3"] = { t: "s", v: "Produto" };
  ws["C3"] = { t: "s", v: "Descrição" };
  ws["D3"] = { t: "s", v: "Estoque" };
  ws["E3"] = { t: "s", v: "Produção Real" };
  ws["F3"] = { t: "s", v: "Venda Grade" };
  ws["G3"] = { t: "s", v: "Disponibilidade" };

  const formatedData = data.map((item) => {
    const disponibility =
      Number(item.inventario.atual) +
      Number(item.producao_real) -
      Number(item.venda_grade);
    return [
      factoryDate,
      item.produto,
      item.descricao,
      quantityFormater(Number(item.inventario.atual || 0)),
      quantityFormater(Number(item.producao_real || 0)),
      quantityFormater(Number(item.venda_grade)),
      quantityFormater(disponibility),
    ];
  });

  utils.sheet_add_json(ws, formatedData, {
    origin: {
      r: 3,
      c: 0,
    },
    skipHeader: true,
  });
  utils.book_append_sheet(wb, ws, "Disponibilidade");
  writeFile(wb, "Disponibilidade.xlsx");
}
