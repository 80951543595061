import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type FactoryResourceDetailType = {
  SK_fabrica_recurso: number;
  FK_recurso: number;
  FK_fabrica: number;
  codigo_linha_de_producao: string;
};

async function fetchFactoryResourceDetail() {
  const response = await instance.get<ApiResponse<FactoryResourceDetailType>>(
    "/planningbeer/factoryResource/details"
  );
  return response.data;
}

export function useFactoryResourceDetail() {
  return useQuery({
    queryKey: ["factoryResourceDetail"],
    queryFn: fetchFactoryResourceDetail,
  });
}
