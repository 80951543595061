import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export const decreaseInventoryKeys = {
  all: ["decreaseInventories"] as const,
};

export type DecreaseInventoryType = {
  SK_baixa_inventario: number;
  FK_baixa_inventario_fabrica: number;
  FK_fabrica: number;
  produto: string;
  quantidade: number;
  dia: string;
  motivo: string;
  abreviatura_empresa: string;
  descricao: string;
  created_by: string;
  created_at: string;
};

async function fetchDecreaseInventories() {
  const response = await instance.post<ApiResponse<DecreaseInventoryType>>(
    "/planningbeer/baixaInventario"
  );

  return response.data;
}

export function useDecreaseInventories() {
  return useQuery({
    queryFn: fetchDecreaseInventories,
    queryKey: decreaseInventoryKeys.all,
  });
}
