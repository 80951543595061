import { DatePicker } from "@/components/DatePicker";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { ProgrammedRealProductionFormType, schema } from "./zod";
import { Button } from "@/components/ui/button";
import Row from "@/components/Row";
import { zodResolver } from "@hookform/resolvers/zod";
import ProductionPlanOldSelect from "@/components/Select/ProductionPlanOldSelect";
import FactoryResourceMultiSelect from "@/components/Select/FactoryResourceMultiSelect";
import { useProductionPlanContext } from "@/pages/PlanoProducao/context";

type ProgrammedRealProductionFormProps = {
  fkProductionPlan: number;
  onSubmit: (data: ProgrammedRealProductionFormType) => void;
};

export default function ProgrammedRealProductionForm({
  onSubmit,
  fkProductionPlan,
}: ProgrammedRealProductionFormProps) {
  const form = useForm<ProgrammedRealProductionFormType>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const {
    productionPlan: { FK_fabrica },
  } = useProductionPlanContext();

  function handleProgrammedRealProduction(value) {
    const previousValue = form.getValues("fkProductionPlanOld") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("fkProductionPlanOld", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("fkProductionPlanOld", values);
  }

  function handleResourcesChange(value) {
    const previousValue = form.getValues("resource") || [];

    if (previousValue.includes(value)) {
      const newValues = previousValue.filter((item) => item !== value);
      return form.setValue("resource", newValues);
    }

    const values = [...previousValue, value];
    form.setValue("resource", values);
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-row gap-4 w-full "
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Row className="w-full gap-4">
          <FormField
            control={form.control}
            name="initialPeriod"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Período Inicial *</FormLabel>
                <FormControl>
                  <DatePicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="finalPeriod"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Período Final *</FormLabel>
                <FormControl>
                  <DatePicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="fkProductionPlanOld"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Versões</FormLabel>
                <ProductionPlanOldSelect
                  fkProductionPlan={fkProductionPlan}
                  handleSelected={handleProgrammedRealProduction}
                  selected={field.value || []}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="resource"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Recurso da Fábrica</FormLabel>
                <FactoryResourceMultiSelect
                  fieldToFilter="FK_fabrica"
                  handleSelected={handleResourcesChange}
                  selected={field.value || []}
                  valueToFilter={FK_fabrica}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <Row align="end">
          <Button type="submit" className="mt-4">
            Gerar
          </Button>
        </Row>
      </form>
    </Form>
  );
}
