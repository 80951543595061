import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import React, { memo } from "react";
import { Separator } from "@/components/ui/separator";
import { FaArrowRight } from "react-icons/fa6";
import { TEXT_COLOR } from "@/constants";
import { Button } from "@/components/ui/button";
import { SelectedPalletType } from "../Modal/LoadPlanner";
import { quantityFormater } from "@/utils/number";

type PalletRowProps = {
  palletId: string;
  composition: string;
  day: string;
  fkSupplier: string;
  selectedPallets: string[];
  quantityPerPallet?: number | string;
  index?: number;
  onCheckPallete: (data: Omit<SelectedPalletType, "supplierName">) => void;
};

const PalletRow = ({
  composition,
  day,
  palletId,
  selectedPallets,
  fkSupplier,
  index = 0,
  quantityPerPallet = 0,
  onCheckPallete,
}: PalletRowProps) => {
  const isSelected = selectedPallets.includes(palletId);

  if (isSelected) return null;
  return (
    <React.Fragment>
      <Row align="center" justify="space-between" className="p-1">
        <Row className="gap-2 p-2" align="center">
          <Button
            size="icon"
            variant="outline"
            onClick={() => {
              onCheckPallete({
                composition: composition,
                pallet: palletId,
                day: day,
                supplier: fkSupplier,
                quantityPerPallet: Number(quantityPerPallet || 0),
                type: "default",
              });
            }}
          >
            <FaArrowRight size={16} color={TEXT_COLOR} />
          </Button>
          <Text>PLT {index + 1}</Text>
          <Text>
            Quantidade por Palete:{" "}
            {quantityFormater(Number(quantityPerPallet || 0))}
          </Text>
        </Row>
      </Row>
      <Separator />
    </React.Fragment>
  );
};

export default memo(PalletRow);
