import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { SelectedTransportersType } from "../components/Steps/BuildLoad/LoadPackaging";

type SelectedTransportersValueType = {
  [supplierWithCargo: string]: SelectedTransportersType;
};

type HandleTransporterSelectProps = {
  supplier: string;
  fkSupplier: string;
  transporter: string;
  cargoNumber: number;
  palletPerCargo: number;
  totalCargos: number;
  cargoName: string;
};

type HandleDateSelectProps = {
  supplier: string;
  cargoCode: number;
  day: string;
};

type LoadBuildContextProps = {
  selectedTransportersValues: SelectedTransportersValueType;
  handleTransporterSelect: (data: HandleTransporterSelectProps) => void;
  handleDateSelect: (data: HandleDateSelectProps) => void;
  removeAllBySupplier: (supplier: string) => void;
  getSelectedInformationsBySupplier: (
    supplier: string
  ) => SelectedTransportersType | undefined;
  removeSupplierSelected: (supplier: string, quantity: number) => void;
  clear: () => void;
};

const LoadBuildContext = createContext({} as LoadBuildContextProps);

export default function LoadBuildProvider({ children }: ReactChildrenType) {
  const [selectedTransportersValues, setSelectedTransportersValues] =
    useState<SelectedTransportersValueType>({});

  const handleTransporterSelect = ({
    cargoNumber,
    fkSupplier,
    palletPerCargo,
    supplier,
    transporter,
    totalCargos,
    cargoName,
  }: HandleTransporterSelectProps) => {
    setSelectedTransportersValues((prev) => {
      return {
        ...prev,
        [`${supplier}-${cargoNumber}`]: {
          transporter,
          fkSupplier,
          palletPerCargo,
          cargoNumber,
          totalCargos,
          day: "",
          cargoName,
        },
      };
    });
  };

  function handleDateSelect({
    supplier,
    cargoCode,
    day,
  }: HandleDateSelectProps) {
    setSelectedTransportersValues((prev) => ({
      ...prev,
      [`${supplier}-${cargoCode}`]: {
        ...prev[`${supplier}-${cargoCode}`],
        day,
      },
    }));
  }

  function getSelectedInformationsBySupplier(supplier: string) {
    const entry = Object.entries(selectedTransportersValues).find(
      ([key]) => key.includes(supplier) // Verifica se a chave contém o fornecedor
    );

    return entry ? entry[1] : undefined;
  }

  function removeSupplierSelected(supplier: string, quantity: number) {
    const quantityToKeep = Math.floor(quantity);

    const keys = Object.keys(selectedTransportersValues)
      .filter((key) => key.startsWith(`${supplier}-`))
      .reverse();
    if (quantityToKeep >= keys.length) {
      return;
    }
    const keysToKeep = keys.slice(-quantityToKeep);
    const otherKeys = Object.keys(selectedTransportersValues).filter(
      (key) => !key.startsWith(`${supplier}-`)
    );

    const allKeysToKeep = [...otherKeys, ...keysToKeep];

    const newSelectedTransportersValues = Object.fromEntries(
      Object.entries(selectedTransportersValues).filter(([key]) =>
        allKeysToKeep.includes(key)
      )
    );
    setSelectedTransportersValues(newSelectedTransportersValues);
  }

  function removeAllBySupplier(supplier: string) {
    const keysToRemove = Object.keys(selectedTransportersValues).filter((key) =>
      key.startsWith(`${supplier}-`)
    );

    if (keysToRemove.length === 0) {
      return;
    }

    const newSelectedTransportersValues = Object.fromEntries(
      Object.entries(selectedTransportersValues).filter(
        ([key]) => !key.startsWith(`${supplier}-`)
      )
    );

    setSelectedTransportersValues(newSelectedTransportersValues);
  }

  function clear() {
    setSelectedTransportersValues({});
  }

  return (
    <LoadBuildContext.Provider
      value={{
        selectedTransportersValues,
        clear,
        removeAllBySupplier,
        getSelectedInformationsBySupplier,
        handleTransporterSelect,
        handleDateSelect,
        removeSupplierSelected,
      }}
    >
      {children}
    </LoadBuildContext.Provider>
  );
}

export function useLoadBuildContext() {
  return useContext(LoadBuildContext);
}
