import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { SelectProps } from "@radix-ui/react-select";
import Each from "../Each";
import {
  productionSimulationsKey,
  ProductionSimulationType,
} from "@/hooks/useProductionSimulators";
import dayjs from "dayjs";

export type ProductionSimulatorData = {
  data: Array<ProductionSimulationType>;
};

type ProductionSimulatorSelectProps = {
  filterByProductionPlan?: {
    fkProductionPlan: number;
  };
  filterByStatus?: "RASCUNHO" | "PUBLICADO" | "APROVADO";
} & SelectProps;

function filterByProductionPlan(
  productionSimulator: ProductionSimulationType[],
  fkProductionPlan?: number
) {
  if (!fkProductionPlan) return productionSimulator;
  return productionSimulator.filter(
    (item) => item.FK_plano_producao == fkProductionPlan
  );
}

function filterByStatus(
  productionSimulator: ProductionSimulationType[],
  status?: "RASCUNHO" | "PUBLICADO" | "APROVADO"
) {
  if (!status) return productionSimulator;
  return productionSimulator.filter((item) => item.status == status);
}

export default function ProductionSimulatorSelect(
  props: ProductionSimulatorSelectProps
) {
  const queryClient = useQueryClient();

  const productionSimulatorData: ProductionSimulatorData | undefined =
    queryClient.getQueryData(productionSimulationsKey.all);
  const { data: productionSimulator } = productionSimulatorData || {};
  if (!productionSimulator) return null;

  const filteredByProductionPlan = filterByProductionPlan(
    productionSimulator,
    props.filterByProductionPlan?.fkProductionPlan
  );

  const optionsByStatus = filterByStatus(
    filteredByProductionPlan,
    props.filterByStatus
  );

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={optionsByStatus}
          renderItem={(props) => {
            return (
              <SelectItem value={String(props.SK_simulacao)}>
                {props.SK_simulacao}, {props.observacao}
                {" - "}
                {props.codigo}
                {", "}
                {props.abreviatura_empresa}{" "}
                {dayjs(props.periodo_inicio, "YYYY-MM-DD").format("DD/MM/YYYY")}
                {" - "}
                {dayjs(props.periodo_fim, "YYYY-MM-DD").format("DD/MM/YYYY")}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}
