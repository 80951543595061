import Table from "@/components/NewTable";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { TEXT_COLOR } from "@/constants";
import { GrDocument } from "react-icons/gr";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";

type GeneratedDeliveryAuthorizationModalType = {
  code: string;
  composition: string;
  description: string;
  delivery_day: string;
  quantity: number;
  created_at: string;
};

export type SimulatorTableType =
  TableData<GeneratedDeliveryAuthorizationModalType>;

const columnHelper = createColumnHelper<SimulatorTableType>();

export default function GeneratedDeliveryAuthorizationModal() {
  const columns = [
    columnHelper.accessor("code", {
      header: "Código",
      id: "code",
      size: 200,
    }),
    columnHelper.accessor("composition", {
      header: "Composição",
      id: "composition",
      size: 150,
    }),
    columnHelper.accessor("description", {
      header: "Descrição",
      id: "description",
      size: 400,
    }),
    columnHelper.accessor("delivery_day", {
      header: "Dia de Entrega",
      id: "delivery_day",
      size: 200,
    }),
    columnHelper.accessor("quantity", {
      header: "Quantidade",
      id: "quantity",
      size: 150,
    }),
    columnHelper.accessor("created_at", {
      header: "Data de Criação",
      id: "created_at",
      size: 150,
    }),
    columnHelper.display({
      header: "Ações",
      id: "actions",
      cell: () => {
        return <Button variant="link">Visualizar</Button>;
      },
    }),
  ];

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="link">
          <Row align="center">
            <GrDocument color={TEXT_COLOR} size={18} />
            <Text className="ml-1 mt-1">
              Visualizar Autorizações de Entrega Geradas
            </Text>
          </Row>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-11/12 max-w-full h-full">
        <Table<SimulatorTableType>
          columns={columns}
          data={[]}
          meta={{
            layout: "stretch",
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
