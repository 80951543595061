import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { decreaseInventoryKeys } from "./useDecreaseInventories";

export default function useDecreaseInventoryDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      return await instance.delete("/planningbeer/baixaInventario", {
        data: { id },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: decreaseInventoryKeys.all });
    },
  });
}
