export function convertObjectArrayToObject<T, K extends keyof T>(
  objectArray: T[] | null | undefined,
  keyProp: K
): Record<string, T> {
  if (!objectArray) return {};
  return objectArray.reduce(
    (acc, obj) => {
      const key = String(obj[keyProp]);
      return { ...acc, [key]: obj };
    },
    {} as Record<string, T>
  );
}
