import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKey } from "./useBuildLoads";

type UpdateBuildLoadStatusMutationType = {
  skBuildLoad: number;
  status: string;
  refusedReason?: string;
};

export function useUpdateBuildLoadStatusMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UpdateBuildLoadStatusMutationType) => {
      return await instance.put("/planningbeer/buildLoad/status", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.all });
    },
  });
}
