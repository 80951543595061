import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const packagingSupplierSchema = z.object({
  fkPackaging: z.array(z.string({ required_error: REQUIRED_FIELD }), {
    required_error: REQUIRED_FIELD,
  }),
  fkSupplier: z.array(z.string({ required_error: REQUIRED_FIELD }), {
    required_error: REQUIRED_FIELD,
  }),
  fkFactory: z.array(z.string({ required_error: REQUIRED_FIELD }), {
    required_error: REQUIRED_FIELD,
  }),
  leadTime: z.string({ required_error: REQUIRED_FIELD }),
  palleteQuantity: z.string({ required_error: REQUIRED_FIELD }),
  operationType: z.string({ required_error: REQUIRED_FIELD }),
});

export const packagingSupplierEditSchema = z.object({
  fkPackaging: z.string({ required_error: REQUIRED_FIELD }),
  fkSupplier: z.string({ required_error: REQUIRED_FIELD }),
  fkFactory: z.string({ required_error: REQUIRED_FIELD }),
  leadTime: z.string({ required_error: REQUIRED_FIELD }),
  palleteQuantity: z.string({ required_error: REQUIRED_FIELD }),
  operationType: z.string({ required_error: REQUIRED_FIELD }),
});

export type PackagingSupplierFormType = z.infer<typeof packagingSupplierSchema>;
export type PackagingSupplierEditFormType = z.infer<
  typeof packagingSupplierEditSchema
>;
