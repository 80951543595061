import { Dialog, DialogContent, DialogPortal } from "@/components/ui/dialog";
import { VersionDropdownProps } from "../Dropdown/VersionDropdown";
import { DialogProps } from "@/types";
import Row from "@/components/Row";
import { IoAlertCircleOutline } from "react-icons/io5";
import { ROUTES, TEXT_COLOR } from "@/constants";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { usePackagingPlanVersionMutation } from "../../hooks/usePackagingPlanVersionMutation";
import { validationError } from "@/utils/api";
import { usePackagingPlanContext } from "../../context";
import { useNavigate } from "react-router-dom";

type EditCurrentVersionDialogProps = VersionDropdownProps & DialogProps;

export default function EditCurrentVersionDialog({
  handleClose,
  visible,
  lineValues,
  revision,
}: EditCurrentVersionDialogProps) {
  const navigate = useNavigate();
  const {
    packagingPlan: { SK_plano_embalagem },
  } = usePackagingPlanContext();
  const { mutateAsync, isPending } = usePackagingPlanVersionMutation();

  async function handleEditVersion() {
    if (isPending) return;
    try {
      await mutateAsync({
        revision: String(revision),
        skPackagingPlan: SK_plano_embalagem,
        supplierValues: JSON.stringify(lineValues),
      });
      return navigate("/" + ROUTES.packagingPlan.index);
    } catch (e) {
      validationError(e);
    }
  }

  return (
    <Dialog onOpenChange={handleClose} open={visible}>
      <DialogPortal>
        <DialogContent>
          <Row>
            <IoAlertCircleOutline size={35} color={TEXT_COLOR} />
            <Text className="font-bold mt-1 ml-1 text-lg">
              Mudança de Versão
            </Text>
          </Row>
          <Text className="mt-4 text-sm">
            Você tem certeza de que deseja alterar a versão do plano de
            ressuprimento de embalagem? Ao prosseguir, todas as versões
            posteriores serão invalidadas e não poderão ser utilizadas
            novamente, os valores preenchidos não serão utilizados em novas
            simulações ou edição do plano de ressuprimento de embalagem. Esta
            ação é irreversível.
          </Text>
          <Row justify="end" className="gap-2">
            <Button onClick={handleClose} disabled={isPending}>
              <Text color="white">Cancelar</Text>
            </Button>
            <Button
              variant="outline"
              disabled={isPending}
              onClick={handleEditVersion}
            >
              <Text>Confirmar Mudança</Text>
            </Button>
          </Row>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
