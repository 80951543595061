import {
  ColumnFiltersState,
  ColumnOrderState,
  ExpandedState,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { cn } from "@/lib/utils";
import TableSettings from "./components/TableSettings";
import TableHeader from "./components/TableColumnHeader";
import TableCell from "./components/TableCell";
import { TableCustomData, TableProps } from "./type";
import { Checkbox } from "../ui/checkbox";
import TablePagination from "./components/TablePagination";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { type DragEndEvent, useDndMonitor } from "@dnd-kit/core";

const Table = <T extends TableCustomData<T>>({
  columns,
  data,
  selectedRowId,
  defaultColumn,
  virtualize = true,
  rowSelection,
  expandedState,
  columnOrderState,
  tableState,
  meta,
  paginationState,
  columnFiltersState,
  cellSize = 50,
  visibleTableSeetings = true,
  enableRowSelection,
  onPaginationChange,
  onColumnFiltersChange,
  onTableStateChange,
  onExpandedStateChange,
  onRowSelectionChange,
  onColumnOrderStateChange,
  getTableInstance,
  getRowId,
  onRowClick,
}: TableProps<T>) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [pagination, setPagination] = useState<PaginationState>(
    paginationState || { pageIndex: 0, pageSize: 0 }
  );

  const columnsMemo = useMemo(
    () =>
      rowSelection
        ? [
            {
              id: "Seleção",
              header: ({ table }) => (
                <Checkbox
                  className={cn("w-5 h-5 rounded-full mx-auto")}
                  checked={table.getIsAllRowsSelected()}
                  onClick={
                    pagination
                      ? table.getToggleAllPageRowsSelectedHandler()
                      : table.getToggleAllRowsSelectedHandler()
                  }
                />
              ),
              cell: ({ row }) => {
                return (
                  row.getCanSelect() && (
                    <Checkbox
                      className={cn(
                        "hidden w-5 h-5 rounded-full group-hover:block",
                        row.getIsSelected() && "block"
                      )}
                      disabled={!row.getCanSelect()}
                      checked={row.getIsSelected()}
                      onCheckedChange={(value) => row.toggleSelected(!!value)}
                    />
                  )
                );
              },
              size: 50,
              enableResizing: false,
              meta: {
                enableColumnOrdering: false,
                enableMenu: false,
              },
            },
            ...columns,
          ]
        : columns,
    [columns]
  );

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

  useEffect(() => {
    setColumnOrder(columnsMemo.map((column) => column.id || ""));
  }, [columnsMemo]);

  const state = {
    ...tableState,
    sorting,
    columnFilters: columnFiltersState || columnFilters,
    expanded: expandedState || expanded,
    rowSelection: rowSelection || selectedRows,
    columnOrder: columnOrderState || columnOrder,
  };

  const table = useReactTable({
    data,
    columns: columnsMemo,
    state,
    meta,
    manualPagination: true,
    enableRowSelection,
    initialState: {
      columnOrder: columnsMemo.map((column) => column.id || ""),
    },
    onStateChange: (updaterOrValue) => {
      if (!onTableStateChange) return;

      if (typeof updaterOrValue === "function") {
        if (tableState) {
          onTableStateChange(
            updaterOrValue({
              ...tableState,
              pagination: {
                pageIndex: 0,
                pageSize: 0,
              },
            })
          );
        }
      } else {
        onTableStateChange(updaterOrValue);
      }
    },
    filterFromLeafRows: true,
    columnResizeMode: "onChange",
    //   paginateExpandedRows: false,
    getRowId,
    getSubRows: (row) => row.subRows as T[],
    onColumnOrderChange: (updaterOrValue) => {
      if (!onColumnOrderStateChange)
        return typeof updaterOrValue === "function"
          ? setColumnOrder
          : setColumnOrder(updaterOrValue);

      if (typeof updaterOrValue === "function") {
        if (columnOrderState) {
          onColumnOrderStateChange(updaterOrValue(columnOrderState));
        }
      } else {
        onColumnOrderStateChange(updaterOrValue);
      }
    },
    onRowSelectionChange: (updaterOrValue) => {
      if (!onRowSelectionChange) return setSelectedRows(updaterOrValue);

      if (typeof updaterOrValue === "function") {
        if (rowSelection) {
          onRowSelectionChange(updaterOrValue(rowSelection));
        }
      } else {
        onRowSelectionChange(updaterOrValue);
      }
    },
    onExpandedChange: (updaterOrValue) => {
      if (!onExpandedStateChange) return setExpanded(updaterOrValue);

      if (typeof updaterOrValue === "function") {
        if (expandedState) {
          onExpandedStateChange(updaterOrValue(expandedState));
        }
      } else {
        onExpandedStateChange(updaterOrValue);
      }
    },
    onPaginationChange: (updaterOrValue) => {
      if (!onPaginationChange) return setPagination(updaterOrValue);

      if (typeof updaterOrValue === "function") {
        if (pagination) {
          onPaginationChange(updaterOrValue(pagination));
        }
      } else {
        onPaginationChange(updaterOrValue);
      }
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: (updaterOrValue) => {
      if (!onColumnFiltersChange) return setColumnFilters(updaterOrValue);

      if (typeof updaterOrValue === "function") {
        if (columnFiltersState) {
          onColumnFiltersChange(updaterOrValue(columnFiltersState));
        }
      } else {
        onColumnFiltersChange(updaterOrValue);
      }
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
    defaultColumn,
    filterFns: undefined,
  });
  const columnsLeaf = table.getVisibleLeafColumns();
  const visibleColumns = columnsLeaf.filter((column) => !column.getIsPinned());

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableRef.current,
    estimateSize: () => cellSize,
    count: table.getExpandedRowModel().rows.length,
  });

  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: (index) => visibleColumns[index].getSize(), //estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableRef.current,
    horizontal: true,
    overscan: 5, //how many columns to render on each side off screen each way (adjust this for performance)
  });

  const virtualColumns = columnVirtualizer.getVirtualItems();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      table.setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  };

  useDndMonitor({
    onDragEnd: handleDragEnd,
  });

  const { getVirtualItems, getTotalSize } = rowVirtualizer;

  const tableRows = table.getRowModel().rows;
  const rows = virtualize ? getVirtualItems() : tableRows;

  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  useEffect(() => {
    if (getTableInstance) getTableInstance(table);
  }, []);

  return (
    <div className="flex flex-col w-full h-fit overflow-hidden">
      {visibleTableSeetings && (
        <div>
          <TableSettings table={table} />
        </div>
      )}
      <div
        ref={virtualize ? tableRef : null}
        className="relative flex-1 w-full overflow-auto"
      >
        {/* table */}
        <div
          className="flex flex-col min-w-full"
          style={{
            width: table.getTotalSize(),
          }}
        >
          {/* thead */}
          <div className="sticky top-0 z-10 bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              // tr
              <div className="flex" key={headerGroup.id}>
                <SortableContext
                  items={table.getState().columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map((header) => (
                    // th
                    <TableHeader
                      key={header.id}
                      header={header}
                      table={table}
                    />
                  ))}
                </SortableContext>
              </div>
            ))}
          </div>

          {/* tbody */}
          <div
            className="relative"
            {...(virtualize && {
              style: {
                height: `${getTotalSize()}px`,
              },
            })}
          >
            {rows.map((item, idx) => {
              const row = virtualize
                ? (tableRows[item.index] as Row<T>)
                : (item as Row<T>);

              if (!row) return null;

              const visibleCells = row.getVisibleCells();
              const selectedTableRow = table
                .getRowModel()
                .rows.find((trow) => trow?.id === selectedRowId);

              const isSelected =
                selectedTableRow &&
                (selectedTableRow?.id === row?.id ||
                  row
                    ?.getParentRows()
                    .some(
                      (parentRow) => parentRow.id === selectedTableRow?.id
                    ));

              const hasParentRow = row?.depth !== 0;

              const isParentRow =
                !hasParentRow && selectedTableRow?.id === row?.id;

              const isLastRow =
                hasParentRow &&
                row?.id ===
                  selectedTableRow?.subRows[
                    selectedTableRow?.subRows.length - 1
                  ]?.id;
              return (
                // tr
                <div
                  {...(virtualize && {
                    style: {
                      height: `${item.size}px`,
                      transform: `translateY(${item.start}px)`,
                      position: "absolute",
                    },
                  })}
                  className={cn(
                    "flex w-full group",
                    onRowClick ? "cursor-pointer" : "",
                    idx % 2 === 0 ? "" : "bg-slate-50",
                    isSelected || row.getIsSelected()
                      ? "bg-slate-200/80 hover:bg-slate-300"
                      : "hover:bg-slate-200",
                    isParentRow && "rounded-tl-lg rounded-tr-lg",
                    isLastRow && "rounded-bl-lg rounded-br-lg",
                    meta?.row?.className
                  )}
                  key={row?.id}
                  onClick={() => (onRowClick ? onRowClick({ row }) : null)}
                >
                  {virtualPaddingLeft ? (
                    //fake empty column to the left for virtualization scroll padding
                    <td
                      style={{ display: "flex", width: virtualPaddingLeft }}
                    />
                  ) : null}
                  {visibleCells
                    .filter((c) => c.column.getIsPinned() == "left")
                    .map((cell) => {
                      return (
                        <SortableContext
                          key={cell.id}
                          items={table.getState().columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          <TableCell
                            key={cell.id}
                            table={table}
                            cell={cell}
                            isRowSelected={
                              cell.row.getIsSelected() || !!isSelected
                            }
                          />
                        </SortableContext>
                      );
                    })}
                  {virtualColumns.map((vc) => {
                    const cell = visibleCells.filter(
                      (c) => !c.column.getIsPinned()
                    )[vc.index];

                    if (!cell) return null;
                    return (
                      <SortableContext
                        key={cell.id}
                        items={table.getState().columnOrder}
                        strategy={horizontalListSortingStrategy}
                      >
                        <TableCell
                          key={cell.id}
                          table={table}
                          cell={cell}
                          isRowSelected={
                            cell.row.getIsSelected() || !!isSelected
                          }
                        />
                      </SortableContext>
                    );
                  })}
                  {virtualPaddingRight ? (
                    //fake empty column to the right for virtualization scroll padding
                    <td
                      style={{ display: "flex", width: virtualPaddingRight }}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {paginationState && onPaginationChange && (
        <TablePagination
          totalItems={paginationState.totalItems}
          totalPages={paginationState.totalPages}
          selectedPage={paginationState.pageIndex}
          onPageChange={(page) =>
            onPaginationChange({
              pageIndex: page,
              pageSize: paginationState.pageSize,
            })
          }
        />
      )}
    </div>
  );
};

export default Table;
