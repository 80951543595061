import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import useTransporters, { TransporterType } from "./hooks/useTransporters";
import { useEffect, useState } from "react";
import Transfer from "@/components/Transfer";
import Each from "@/components/Each";
import Text from "@/components/Text/Text";
import { Separator } from "@/components/ui/separator";
import { useTransporterActiveMutation } from "./hooks/useTransporterActiveMutation";
import { validationError } from "@/utils/api";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import TransporterItem from "./components/Item/TransferItem";

type SelectedItemsType = {
  position: "left" | "right";
  codigo: string;
};

export default function Transporter() {
  const [leftInputFilter, setLeftInputFilter] = useState<string>("");
  const [rightInputFilter, setRightInputFilter] = useState<string>("");
  const { mutateAsync, isPending } = useTransporterActiveMutation();
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItemsType>>(
    []
  );
  const [transportes, setTransporters] = useState<Array<TransporterType>>([]);
  const { data } = useTransporters();
  const { data: transportersData = [] } = data || {};

  const activeTransporters = transportes.filter(
    (transporter) => transporter.ativo == 1
  );

  const inactiveTransporters = transportes.filter(
    (transporter) => transporter.ativo == 0
  );

  const filteredInactiveTransporters = inactiveTransporters.filter(
    ({ nome_fantasia }) => {
      return nome_fantasia
        .toUpperCase()
        .includes(leftInputFilter.toUpperCase());
    }
  );

  const filteredActiveTransporters = activeTransporters.filter(
    ({ nome_fantasia }) => {
      return nome_fantasia
        .toUpperCase()
        .includes(rightInputFilter.toUpperCase());
    }
  );

  function handleProductSelection({ codigo, position }: SelectedItemsType) {
    setSelectedItems((prev) => {
      const isSelected = prev.some((item) => item.codigo === codigo);

      if (isSelected) {
        return prev.filter((item) => item.codigo !== codigo);
      }

      return [...prev, { codigo, position }];
    });
  }

  function handleLeftToRight() {
    if (!selectedItems.length) return;
    const selectedItemsCodes = selectedItems.map((item) => item.codigo);
    const updatedTransporters = transportes.map((transporter) => {
      if (selectedItemsCodes.includes(transporter.codigo)) {
        return { ...transporter, ativo: 1 };
      }
      return transporter;
    });

    setTransporters(updatedTransporters);
    setSelectedItems([]);
  }

  function handleRightToLeft() {
    if (!selectedItems.length) return;
    const selectedItemsCodes = selectedItems.map((item) => item.codigo);
    const updatedTransporters = transportes.map((transporter) => {
      if (selectedItemsCodes.includes(transporter.codigo)) {
        return { ...transporter, ativo: 0 };
      }
      return transporter;
    });

    setTransporters(updatedTransporters);
    setSelectedItems([]);
  }

  function handleAllRightToLeft() {
    const updatedProducts = transportersData.map((product) => {
      return { ...product, ativo: 0 };
    });

    setTransporters(updatedProducts);
    setSelectedItems([]);
  }

  function handleAllLeftToRight() {
    const updatedProducts = transportersData.map((product) => {
      return { ...product, ativo: 1 };
    });

    setTransporters(updatedProducts);
    setSelectedItems([]);
  }

  async function handleUpdate() {
    if (isPending) return;
    const data = activeTransporters.map((item) => item.codigo);
    try {
      await mutateAsync({ codes: data });
    } catch (er) {
      validationError(er);
    }
  }

  useEffect(() => {
    if (!transportersData) return;
    setTransporters(transportersData);
  }, [transportersData]);

  return (
    <AuthenticatedLayout>
      <div className="max-h-[calc(100vh-150px)] border h-full ">
        <Transfer
          titleLeft={
            <>
              <Text className="font-bold text-center mt-2 mb-2">
                Transportadoras
              </Text>
              <Separator />
              <Row className="w-full p-4">
                <Input
                  onChange={(e) => {
                    setLeftInputFilter(e.target.value);
                  }}
                  placeholder="Pesquisar Transportador..."
                />
              </Row>
            </>
          }
          titleRight={
            <>
              <Text className="font-bold text-center mt-2 mb-2">
                Transportadoras Utilizadas Planning Beer
              </Text>
              <Separator />
              <Row className="w-full p-4">
                <Input
                  onChange={(e) => {
                    setRightInputFilter(e.target.value);
                  }}
                  placeholder="Pesquisar Transportador..."
                />
              </Row>
            </>
          }
          leftData={inactiveTransporters}
          rightData={activeTransporters}
          onHandleLeftToRight={handleLeftToRight}
          onHandleRightToLeft={handleRightToLeft}
          onHandleAllLeftToRight={handleAllLeftToRight}
          onHandleAllRightToLeft={handleAllRightToLeft}
          renderLeftElement={() => (
            <Each
              array={filteredInactiveTransporters}
              renderItem={(props) => {
                return (
                  <TransporterItem
                    {...props}
                    isSelected={selectedItems.some(
                      (value) => value.codigo == props.codigo
                    )}
                    onSelectionChange={() =>
                      handleProductSelection({
                        codigo: props.codigo,
                        position: "left",
                      })
                    }
                  />
                );
              }}
            />
          )}
          renderRightElement={() => (
            <Each
              array={filteredActiveTransporters}
              renderItem={(props) => {
                return (
                  <TransporterItem
                    {...props}
                    isSelected={selectedItems.some(
                      (value) => value.codigo == props.codigo
                    )}
                    onSelectionChange={() =>
                      handleProductSelection({
                        codigo: props.codigo,
                        position: "right",
                      })
                    }
                  />
                );
              }}
            />
          )}
        />
      </div>
      <Row justify="end" className="mt-4">
        <Button onClick={handleUpdate}>Salvar</Button>
      </Row>
    </AuthenticatedLayout>
  );
}
