import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type BuildLoadsType = {
  SK_montagem_carga: number;
  FK_fornecedor: string;
  FK_transportadora: string;
  FK_plano_embalagem: number;
  dia: string;
  paletes_por_carga: number;
  numero_carga: number;
  criado_por: string;
  status: string;
  created_at: string;
  D_E_L_E_T_: null;
  nome_fornecedor: string;
  nome_transportadora: string;
  cidade_transportadora: string;
  foi_enviado_integracao: number;
  modelo_carga: string;
  data_envio_integracao: string | null;
  id_integracao: string | null;
  motivo_recusa: string | null;
};

export const queryKey = {
  all: ["buildLoads"] as const,
};

async function fetchBuildLoads(fkPackagingPlan: number) {
  const response = await instance.post<ApiResponse<BuildLoadsType>>(
    "/planningbeer/buildLoad",
    {
      fkPackagingPlan,
    }
  );

  return response.data;
}

export function useBuildLoads(fkPackagingPlan: number) {
  return useQuery({
    queryFn: () => fetchBuildLoads(fkPackagingPlan),
    queryKey: queryKey.all,
    staleTime: 0,
    gcTime: 0,
  });
}
