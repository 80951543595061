import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import { Link, useNavigate } from "react-router-dom";
import { usePackagingPlanContext } from "../context";
import { useEffect } from "react";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import { usePackagingSimulation } from "@/hooks/usePackagingSimulation";
import dayjs from "dayjs";
import PackagingSimulationTable from "@/pages/SimulacoesEmbalagem/components/Table";
import Text from "@/components/Text/Text";
import Can from "@/components/Can";
import DotsLoading from "@/components/Loading/DotsLoading";
import PackagingPlanError from "../components/Error/PackagingPlanError";
import FilterPackagingPlanDropdown from "../components/Dropdown/FilterPackagingPlanDropdown";
import { useProductionSimulators } from "@/hooks/useProductionSimulators";
import { CURRENT_DAY, FORTY_FIVE_DAYS } from "../components/Form/See";
import IncludeSimulatorModal from "../components/Modal/IncludeSimulatorModal";
import VersionModal from "../components/Modal/VersionModal";
import EditPackagingPlanModal from "../components/Modal/EditPackagingPlanModal";
import DownloadExcel from "../components/Button/DownloadExcel";
import DeliveryAuthorizationModal from "../components/Modal/DeliveryAuthorizationModal";
import GeneratedDeliveryAuthorizationModal from "../components/Modal/GeneratedDeliveryAuthorizationModal";
import MenuDropdown from "../components/Dropdown/MenuDropdown";
import LoadModal from "../components/Modal/LoadModal";
import useActiveTransporters from "../hooks/useActiveTransporters";
import LoadPlannerModal from "../components/Modal/LoadPlanner";

export default function SeePackagingPlan() {
  useActiveTransporters();
  useProductionSimulators();
  const navigate = useNavigate();
  const {
    clear: clearPackagingPlan,
    packagingPlan: { SK_plano_embalagem, FK_plano_producao },
    filterPackagingPlan: {
      periodo_fim = FORTY_FIVE_DAYS,
      periodo_inicio = CURRENT_DAY,
    },
  } = usePackagingPlanContext();
  const {
    clear: clearPackagingSimulation,
    handlePackagingSimulationData,
    packagingSupplier: { handlePackagingSupplierCellValue },
  } = usePackagingSimulationContext();

  const { data, isError, isFetching, isRefetching, refetch } =
    usePackagingSimulation({
      meta: {
        FK_plano_producao,
        FK_plano_embalagem: SK_plano_embalagem,
        periodo_fim: dayjs(periodo_fim).format("YYYY-MM-DD"),
        periodo_inicio: dayjs(periodo_inicio).format("YYYY-MM-DD"),
        utiliza_simulador_produto_acabado: false,
      },
    });
  const { data: packagingSimulation } = data || {};
  const { meta } = packagingSimulation || {};
  const { valores_fornecedores: supplierValues = [] } = meta || {};

  useEffect(() => {
    if (!packagingSimulation) return;
    handlePackagingSimulationData(packagingSimulation);
    handlePackagingSupplierCellValue(supplierValues);
  }, [packagingSimulation]);

  useEffect(() => {
    refetch();
  }, [periodo_fim, periodo_inicio]);

  useEffect(() => {
    clearPackagingSimulation();
  }, []);

  if (!SK_plano_embalagem) {
    navigate("/" + ROUTES.packagingPlan.index);
    return <></>;
  }

  return (
    <AuthenticatedLayout
      title="Visualizando Plano de Ressuprimento de Embalagem"
      className="max-h-[calc(100vh-150px)] h-full"
    >
      <Row justify="end" className="gap-2 h-fit">
        <MenuDropdown>
          <GeneratedDeliveryAuthorizationModal />
          <DeliveryAuthorizationModal />
          <VersionModal skPackagingPlan={SK_plano_embalagem} />
          <LoadPlannerModal />
          <LoadModal />
          <DownloadExcel />
        </MenuDropdown>
        <FilterPackagingPlanDropdown />
        <EditPackagingPlanModal />
        <IncludeSimulatorModal />
        <Link to={"/" + ROUTES.packagingSimulations.index}>
          <Button>Cadastrar Simulador</Button>
        </Link>
      </Row>
      <Can
        condition={isError}
        onTrue={<PackagingPlanError />}
        onFalse={
          <div className="h-full">
            <Can
              condition={isFetching || isRefetching}
              onTrue={<DotsLoading />}
            />
            <Can
              condition={!isFetching || !isRefetching}
              onTrue={
                <PackagingSimulationTable
                  editableSupplierCell={false}
                  tableInformation={{
                    showCustom: true,
                    custom: (
                      <Row>
                        <Text className="font-bold text-sm">
                          {dayjs(periodo_inicio).format("DD/MM/YYYY")}
                          {" - "}
                          {dayjs(periodo_fim).format("DD/MM/YYYY")}
                        </Text>
                      </Row>
                    ),
                  }}
                />
              }
            />
          </div>
        }
      />
      <Row justify="end">
        <Button
          variant="outline"
          onClick={() => {
            clearPackagingPlan();
            clearPackagingSimulation();
            navigate("/" + ROUTES.packagingPlan.index);
          }}
        >
          Voltar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}
