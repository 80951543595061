import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { IoMdHelp } from "react-icons/io";

export default function HelpDialog() {
  return (
    <Dialog>
      <DialogTrigger>
        <Button size="icon" variant="outline" type="button">
          <IoMdHelp size={24} color={TEXT_COLOR} />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-full w-4/12 h-3/4">
        <Col>
          <Text className="text-lg font-bold">
            Para realizar a baixa de inventário, siga os passos abaixo:
          </Text>
          <Separator className="mt-2" />
          <Text className="text-base mt-1">
            1 - Baixe o modelo fornecido para a carga de inventário. Preencha os
            seguintes campos:
            <Text className="mt-1 ml-2">
              <strong>Código do Produto: </strong>
              Insira o código correspondente ao produto.
            </Text>
            <Text className="mt-1 ml-2">
              <strong>Quantidade: </strong>
              Informe a quantidade a ser baixada (somente números).
            </Text>
            <Text className="mt-1 ml-2">
              <strong>Motivo: </strong>
              Especifique o motivo da baixa do inventário.
            </Text>
          </Text>
          <Separator className="mt-2" />
          <Text className="mt-1 text-base">2 - Selecione a fábrica.</Text>
          <Separator className="mt-2" />
          <Text className="mt-1 text-base">
            3 - Selecione a data da baixa do inventário.
          </Text>
          <Separator className="mt-2" />
          <Text className="mt-1 text-base">
            4 - Após preencher o modelo, clique em "Escolher arquivo" e
            selecione o arquivo preenchido.
          </Text>
          <Separator className="mt-2" />
          <Text className="mt-1 text-base">
            5 - Clique em "Salvar" para finalizar.
          </Text>
        </Col>
      </DialogContent>
    </Dialog>
  );
}

//  Baixe o
//           modelo fornecido para a carga de inventário. Preencha os seguintes
//           campos: Código do Produto: Insira o código correspondente ao produto.
//           Quantidade: Informe a quantidade a ser baixada (somente números).
//           Motivo: Especifique o motivo da baixa do inventário
