import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useFactoryResourceDetail } from "../../hooks/useFactoryResourceDetail";
import useProducts from "@/hooks/useProducts";
import { generateScheduleProductionSheet } from "../../utils/scheduleProductionSheet";
import dayjs from "dayjs";

export type FormatProductionLineType = {
  FK_produto: string;
  FK_fabrica_recurso: string | number;
  descricao_produto: string;
  codigo_linha_de_producao: string;
  dia: string;
  filial: string;
  valor?: string;
};

export default function ExportScheduleProductionButton() {
  const {
    lines: { lineValues },
    simulationData: { fabrica, simulador_producao },
  } = useSimulationProductionContext();
  const { data: productsData } = useProducts();
  const { data: products } = productsData || {};
  const { data } = useFactoryResourceDetail();
  const { data: factoryResourceDetail } = data || {};

  function formatProductionLineData() {
    if (
      !lineValues ||
      !simulador_producao?.periodo_inicio ||
      !simulador_producao?.periodo_fim ||
      !factoryResourceDetail
    )
      return;
    // Remove one day from the initial day and add one day to the final day to filter get initial and final date
    //prettier-ignore
    const initialDay = dayjs(simulador_producao?.periodo_inicio).subtract(1,"day");
    const finalDay = dayjs(simulador_producao?.periodo_fim).add(1, "day");

    const filteredLines = lineValues.filter((line) => {
      return !initialDay.isBetween(line.dia, finalDay, null, "[]");
    });

    const formatted = filteredLines.map((line) => {
      const productionLineCode = factoryResourceDetail?.find(
        (resource) => resource.SK_fabrica_recurso == line.FK_fabrica_recurso
      );
      const product = products?.find(
        (product) => product.SK_produto == line.FK_produto
      );

      return {
        ...line,
        filial: fabrica?.codigo,
        descricao_produto: product?.descricao || "",
        codigo_linha_de_producao:
          productionLineCode?.codigo_linha_de_producao || "LINHA_6",
      };
    });

    generateScheduleProductionSheet(formatted);
  }

  return (
    <Button
      variant="ghost"
      onClick={formatProductionLineData}
      disabled={!lineValues}
    >
      <Row align="center">
        <RiFileExcel2Fill size={18} color={TEXT_COLOR} />
        <Text className="mt-1 ml-1">Exportar Produção Programada</Text>
      </Row>
    </Button>
  );
}
