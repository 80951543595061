import { Dialog, DialogContent, DialogPortal } from "@/components/ui/dialog";
import { useDecreaseInventoryContext } from "../../context";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { useEffect } from "react";
import { z } from "zod";
import { REQUIRED_FIELD } from "@/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import ProductSelect from "@/components/Select/ProductsSelect";
import FactorySelect from "@/components/Select/FactorySelect";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
import useDecreaseInventoryPatchMutation from "../../hooks/useDecreaseInventoryPatchMutation";
import { validationError } from "@/utils/api";

const schema = z.object({
  quantity: z.string({ required_error: REQUIRED_FIELD }),
  date: z.date({ required_error: REQUIRED_FIELD }),
  fkFactory: z.string({ required_error: REQUIRED_FIELD }),
  fkProduct: z.string({ required_error: REQUIRED_FIELD }),
  reason: z.string(),
});

type EditDecreaseInventoryFormType = z.infer<typeof schema>;

export default function EditDecreaseInventoryDialog() {
  const form = useForm<EditDecreaseInventoryFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { visibleDialog, clear, handleDialog, dialogType, decreaseInventory } =
    useDecreaseInventoryContext();
  const { mutateAsync, isPending } = useDecreaseInventoryPatchMutation();

  function handleEditDialog() {
    handleDialog();
    clear();
  }

  async function handleEditDecreaseInventory(
    data: EditDecreaseInventoryFormType
  ) {
    if (isPending) return;
    try {
      await mutateAsync({
        skDecreaseInventory: decreaseInventory.SK_baixa_inventario,
        fkFactory: data.fkFactory,
        fkProduct: data.fkProduct,
        date: dayjs(data.date).format("YYYY-MM-DD"),
        quantity: data.quantity,
        reason: data.reason,
      });
      return handleEditDialog();
    } catch (err) {
      return validationError(err);
    }
  }

  useEffect(() => {
    form.reset({
      quantity: String(decreaseInventory?.quantidade) || "0",
      fkFactory: String(decreaseInventory.FK_fabrica) || "",
      date: dayjs(decreaseInventory.dia).toDate() || "",
      reason: decreaseInventory?.motivo || "",
      fkProduct: decreaseInventory.produto || "",
    });
    return () => {
      form.reset();
    };
  }, [decreaseInventory]);

  if (dialogType !== "edit" || !decreaseInventory) return;
  return (
    <Dialog onOpenChange={handleEditDialog} open={visibleDialog}>
      <DialogPortal>
        <DialogContent>
          <Form {...form}>
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(handleEditDecreaseInventory)}
            >
              <FormField
                name="fkProduct"
                control={form.control}
                render={({ field }) => {
                  const produto = [String(field.value)];
                  return (
                    <FormItem>
                      <FormLabel>Produto *</FormLabel>
                      <FormControl>
                        <ProductSelect
                          selected={produto}
                          handleSelected={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="date"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Dia *</FormLabel>
                      <FormControl>
                        <DatePicker
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="fkFactory"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Filial *</FormLabel>
                      <FormControl>
                        <FactorySelect
                          onValueChange={field.onChange}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="quantity"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Quantidade *</FormLabel>
                      <FormControl>
                        <Input
                          min={0}
                          type="number"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="reason"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Motivo </FormLabel>
                      <FormControl>
                        <Textarea
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Row justify="end">
                <Button type="submit" disabled={isPending}>
                  Salvar
                </Button>
              </Row>
            </form>
          </Form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
