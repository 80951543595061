import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import {
  transporterKeys,
  TransporterType,
} from "@/pages/Transportador/hooks/useTransporters";
import { useQuery } from "@tanstack/react-query";

async function fetchActiveTransporters() {
  const response = await instance.post<ApiResponse<TransporterType>>(
    "/planningbeer/transporter/active"
  );
  return response.data;
}

export default function useActiveTransporters() {
  return useQuery({
    queryKey: transporterKeys.active,
    queryFn: fetchActiveTransporters,
  });
}
