import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type PackagingSupplierGroupedType = {
  FK_fornecedor: string;
  nome_completo: string;
  embalagem_fornecedores: Array<number>;
};

async function fetchPackagingSupplierGrouped() {
  const response = await instance.post<
    ApiResponse<PackagingSupplierGroupedType>
  >("/planningbeer/packagingSupplier/grouped");

  return response.data;
}

export function usePackagingSupplierGrouped() {
  return useQuery({
    queryKey: ["packagingSupplierGrouped"],
    queryFn: fetchPackagingSupplierGrouped,
    staleTime: 0,
    gcTime: 0,
  });
}
