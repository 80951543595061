import instance from "@/api/business";
import { ApiResponse } from "@/hooks/useEstimates";
import { useQuery } from "@tanstack/react-query";

export type TransporterType = {
  codigo: string;
  razao_social: string;
  nome_fantasia: string;
  cidade: string;
  uf: string;
  email: string;
  cnpj: string;
  logradouro: string;
  ativo: number;
};

export const transporterKeys = {
  all: ["transporters"] as const,
  active: ["activeTransporters"] as const,
};

async function fetchTransporters() {
  const response = await instance.post<ApiResponse<TransporterType>>(
    "/planningbeer/transporter"
  );
  return response.data;
}

export default function useTransporters() {
  return useQuery({
    queryKey: transporterKeys.all,
    queryFn: fetchTransporters,
  });
}
