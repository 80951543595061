import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { HandleCreatePackagesProps } from ".";
import TransporterSelect from "@/components/Select/TransporterSelect";
import { DatePicker } from "@/components/DatePicker";
import CargoModelSelect from "@/components/Select/CargoModelSelect";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Row from "@/components/Row";
import { z } from "zod";
import { REQUIRED_FIELD } from "@/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";

type CreatedPackagesType = {
  onCreatedPackages: (data: HandleCreatePackagesProps) => void;
};

const schema = z.object({
  day: z.date({ required_error: REQUIRED_FIELD }),
  quantityToCreate: z.string({ required_error: REQUIRED_FIELD }).trim(),
  fkTransporter: z.string({ required_error: REQUIRED_FIELD }),
  fkCargoModel: z.string({ required_error: REQUIRED_FIELD }),
});

type FormType = z.infer<typeof schema>;

export default function CreatedPackagesModal({
  onCreatedPackages,
}: CreatedPackagesType) {
  const [visible, setVisible] = useState(false);
  const form = useForm<FormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function handleSubmit({
    day,
    fkCargoModel,
    fkTransporter,
    quantityToCreate,
  }: FormType) {
    const formatedDay = dayjs(day).format("YYYY-MM-DD");
    const [transporterCode, transporterName, transporterCity] =
      fkTransporter.split("_");
    const [cargoModelCode, cargoModelQuantityPerCargo] =
      fkCargoModel.split("_");

    const data = {
      quantityToCreate: Number(quantityToCreate),
      id: 0,
      fkTransporter: transporterCode,
      fkCargoModel: cargoModelCode,
      day: formatedDay,
      transporterName: transporterName + "_" + transporterCity,
      quantityPerCargo: Number(cargoModelQuantityPerCargo),
    };

    onCreatedPackages({ ...data });
    form.reset();
    setVisible((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={() => setVisible((prev) => !prev)} open={visible}>
      <DialogTrigger>
        <Button className="w-full mt-4">Nova Carga</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <FormField
              name="quantityToCreate"
              defaultValue={"1"}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quantidade de Cargas *</FormLabel>
                  <FormControl>
                    <Input {...field} type="number" min={1} max={100} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="day"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Dia *</FormLabel>
                  <FormControl>
                    <DatePicker onChange={field.onChange} value={field.value} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="fkCargoModel"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Modelo de Carga *</FormLabel>
                  <FormControl>
                    <CargoModelSelect
                      onValueChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="fkTransporter"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Transportadora *</FormLabel>
                  <FormControl>
                    <TransporterSelect
                      keyValue={(transporter) => {
                        return (
                          transporter?.codigo +
                          "_" +
                          transporter?.nome_fantasia +
                          "_" +
                          transporter?.cidade
                        );
                      }}
                      onValueChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Row justify="end">
              <Button type="submit">Criar</Button>
            </Row>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
