import { PackagingPlanSimulatorsType } from "../hooks/usePackagingPlanSimulators";

export function formatSupplierValuesToString(
  supplierValues: PackagingPlanSimulatorsType["valores_fornecedores"]
) {
  if (supplierValues.length == 1 && supplierValues[0] == null) {
    return JSON.stringify([]);
  }
  return JSON.stringify(supplierValues);
}
