import DotsLoading from "@/components/Loading/DotsLoading";
import Text from "@/components/Text/Text";

export default function DevOnly() {
  return (
    <>
      <Text>Teste com deploy</Text>
      <DotsLoading />
    </>
  );
}
