import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useCargoModelContext } from "../../context";
import UpdateCargoModelForm from "../Form/Edit";

export default function UpdateCargoModelDialog() {
  const { visibleDialog, clear, dialogType } = useCargoModelContext();
  if (dialogType !== "edit") return null;
  return (
    <Dialog open={visibleDialog} onOpenChange={clear}>
      <DialogContent>
        <UpdateCargoModelForm />
      </DialogContent>
    </Dialog>
  );
}
