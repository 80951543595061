import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { decreaseInventoryKeys } from "./useDecreaseInventories";

type DecreaseInventoryPatchMutationType = {
  skDecreaseInventory: number;
  fkFactory: string;
  fkProduct: string;
  date: string;
  quantity: string;
  reason: string;
};

export default function useDecreaseInventoryPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: DecreaseInventoryPatchMutationType) => {
      return await instance.patch("/planningbeer/baixaInventario", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: decreaseInventoryKeys.all });
    },
  });
}
